import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

// util
import { phone } from '../util/porcentaje';


const useStyles = makeStyles(() => ({
    bgcolor: {
        backgroundColor: 'gainsboro !important' ,
    },
}));

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

export default function Acordion({ estado }) {
    const [expanded, setExpanded] = useState(estado ? estado : 'panel1');
    const classes = useStyles();
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.bgcolor}>
                    <Typography className="lead">¿Cómo funciona la Venta Telefónica?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography className="lead">
                        Sólo debes escribir un texto al siguiente número {phone} ó tan facíl como darle
                        un clip a la ícono de WhatsApp que se encuentra en la parte superior y uno de nuestros
                        ejecutivos te asesorará:
                        qué querés comprar, cómo lo vas a pagar y dónde querés que lo envíen. Además,
                        para asegurar tu compra te solicitaremos un teléfono de contacto, y así nos
                        podremos comunicar para validar la compra.
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header" className={classes.bgcolor}>
                    <Typography className="lead">Métodos y costo de envio</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography className="lead">
                        La entrega de los productos incluye el traslado hasta la puerta del domicilio
                        declarado (puerta del edificio en caso de departamentos). Jalliday,
                        no cuenta con el servicio de subida por escalera.
                        Cualquier persona mayor de 18 años que se encuentre en el domicilio de
                        entrega debe recibir el pedido con:

                        <ul className="mt-3">
                            <li>Número de codigo de referencia.</li>
                            <li>Documento de indentidad, pasaporte, RIF u otros...</li>
                        </ul>
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="mb-5">
                <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header" className={classes.bgcolor}>
                    <Typography className="lead">Proceso de cancelación de pedido</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography className="lead">
                        Si todavía no has recibido notificación y/o SMS confirmando el envio `Su pedido
                        ha sido enviado`. Usted puede ralizar la cancelación del mismo sin ningun. Para ello
                        siga los pasos siguientes,: Inicie sesión. Haga clic en `Cancelar`. Seleccione
                        el o los producto(s) que desea cancelar. Elija el motivo de cancelación y presione
                        el botón `Estoy seguro que quiero cancelar`. Recuerde revisar el estatus de su producto(s),
                        allí encontrará la palabra: Confirmado o Pago pendiente.
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}

Acordion.propTypes = {
    estado: PropTypes.bool
}

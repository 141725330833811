import {
    INSERTAR_CODIGO,
    ERROR_INSERTAR_CODIGO,
    OBTENER_PEDIDO,
    ERROR_OBTENER_PEDIDO,
    MOTIVO_EXITO,
    ERROR_MOTIVO,
} from '../types';

// Axios
import axios from '../config/axios';

export const insertarCodigo = (data) => {
    return async (dispatch) => {
        try {
            const codigoData = await axios.post('/pedido', data);
            dispatch({
                type: INSERTAR_CODIGO,
                payload: codigoData.data
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_INSERTAR_CODIGO,
                payload: alert
            })
        }
    }
}

export const getPedidos = (id) => {
    return async (dispatch) => {
        try {
            const pedido = await axios.get(`/pedido/${id}`);
            dispatch({
                type: OBTENER_PEDIDO,
                payload: pedido.data.pedido
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_OBTENER_PEDIDO,
                payload: alert
            })
        }
    }
}

export const motivoRechazo = (motivo, estado, _id, usuario) => {
    return async (dispatch) => {
        const options = {
            motivo,
            estado,
            usuario
        }
        try {
            await axios.put(`/pedido/${_id}`, options);
            const alert = {
                msg: "Su pedido ha sido cancelado",
                style: "info"
            }
            dispatch({
                type: MOTIVO_EXITO,
                payload: alert
            })
            await getPedidos();
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "info"
            }
            dispatch({
                type: ERROR_MOTIVO,
                payload: alert
            })
        }
    }
}

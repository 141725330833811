import React, { useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import { Button, Menu, MenuItem } from '@material-ui/core';

// Actions
import { inputSeach } from '../../actions/Items';

// Cookie
import { setCookie, getCookie } from '../../util/cookie';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const Category = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.authentification);

    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);
    const [popover, setPopover] = useState(null);
    // const [popover2, setPopover2] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
        setPopover(null);
        // setPopover2(null);
    };

    const open = Boolean(popover);
    const id = open ? 'simple-popover' : undefined;
    // const open2 = Boolean(popover2);
    // const id2 = open ? 'simple-popover' : undefined;

    const handlePedido = (e) => {
        if (auth) {
            history.push({
                pathname: '/perfil',
                state: 3
            });
        } else {
            setPopover(e.currentTarget);
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleInput = (search) => {
        setCookie("LAST_SEARCH", search);
        const busqueda = getCookie('LAST_SEARCH');
        dispatch(inputSeach());
        history.push(`/list-items/s=${busqueda}`);
    }

    const categorias = [
        {
            id: 1,
            name: 'Dpto. Cafetería',
            search: 'Cafetería',
        },
        {
            id: 2,
            name: 'Dpto. Grasas y Aceites',
            search: 'Grasas y Aceites',
        },
        {
            id: 3,
            name: 'Dpto. Cereales y Granos Enteros',
            search: 'Cereales y Granos Enteros',
        },
        {
            id: 4,
            name: 'Dpto. Salsas y Aderezos',
            search: 'Salsas y Aderezos',
        },
        {
            id: 5,
            name: 'Dpto. Pescados y Enlatados',
            search: 'Pescados y Enlatados',
        },
        {
            id: 6,
            name: 'Dpto. Pastas y Fideos',
            search: 'Pastas y Fideos',
        },
        {
            id: 7,
            name: 'Dpto. Higiene y Limpieza',
            search: 'Higiene y Limpieza',
        },
        {
            id: 8,
            name: 'Dpto. Galletas y Golosinas',
            search: 'Galletas y Golosinas',
        },
        {
            id: 9,
            name: 'Dpto. Fármacos y Medicamentos',
            search: 'Fármacos y Medicamentos',
        },
        {
            id: 10,
            name: 'Dpto. Cosmético',
            search: 'Cosmético',
        },
        {
            id: 11,
            name: 'Dpto. Bebidas y Lácteos',
            search: 'Bebidas y Lácteos',
        },
    ]

    return (
        <div
            className="btn-group"
            role="group"
            aria-label="Basic example">

            <Button
                className="nav-link dropdown-toggle"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e)}>
                Categoría
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
            >
                {categorias.map(cat => (
                    <MenuItem key={cat.id} onClick={() => handleInput(cat.search)}>{cat.name}</MenuItem>
                ))}
            </Menu>

            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => handleInput("Oferta")}>
                {/*<Link to={"/offert"} className="list-none text-dark"></Link>*/}
                Oferta
            </Button>

            <Button
                className="hide"
                aria-controls="simple-menu"
                aria-haspopup="true">
                <Link to={'/servicio-cliente'}
                    className="list-none text-dark">
                    Servicio al cliente
                </Link>
            </Button>

            <Button
                className="hide"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => handlePedido(e)}>
                Mis Pedidos
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={popover}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>Para ver tus pedidos debes iniciar sesión</Typography>
            </Popover>

            <Button
                className="hide"
                aria-controls="simple-menu"
                aria-haspopup="true">
                <Link to={"/help"} className="list-none text-dark">
                    Ayuda
                </Link>
            </Button>

        </div>
    );
}

export default Category;
import {
    INPUT_SEARCH,
    GET_PRODUCTO_ID,
    ERROR_PRODUCTO_ID,
    ERROR_INPUT_SEARCH,
    AGREGAR_CARRITO,
    UPDATE_ESTADO,
    AGREGAR_TOTAL,
    AGREGAR_SUMA,
    RESTAR_PRODUCTO,
    SUMAR_ITEM,
    EMPTY,
    EMPTY_CARRITO,
    EMPTY_ITEM,
} from '../types';

import { getCookie } from '../util/cookie';

// Cliente de axios
import axios from '../config/axios';

export const inputSeach = () => {
    return async (dispatch) => {

        var cookie = getCookie('LAST_SEARCH');

        try {
            if (cookie) {
                const search = await axios.get(`productos/${cookie}`);
                dispatch({
                    type: INPUT_SEARCH,
                    payload: search.data.producto
                });
            }
        } catch (error) {
            dispatch({
                type: ERROR_INPUT_SEARCH,
                payload: true
            });
        }

    }
}

export const obtenerProducto = (id) => {
    return async (dispatch) => {
        try {
            const producto = await axios.get(`productos/producto/${id}`);
            dispatch({
                type: GET_PRODUCTO_ID,
                payload: producto.data
            });
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            };
            dispatch({
                type: ERROR_PRODUCTO_ID,
                payload: alert
            });
        }
    }
}

export const addCarrito = (carrtio) => {
    return (dispatch) => {
        dispatch({
            type: AGREGAR_CARRITO,
            payload: carrtio
        });
    }
}

export const totals = (tlt) => {
    return (dispatch) => {
        dispatch({
            type: AGREGAR_TOTAL,
            payload: tlt
        });
    }
}

export const sumas = (ListProducto) => {
    return (dispatch) => {
        const suma = [ListProducto.total].reduce(function (a, b) { return a + b });
        dispatch({
            type: AGREGAR_SUMA,
            payload: suma
        });
    }
}

export const updateEstado = (estado) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ESTADO,
            payload: estado
        });
    }
}

export const updateSuma = (resta) => {
    return (dispatch) => {
        dispatch({
            type: RESTAR_PRODUCTO,
            payload: resta
        });
    }
}

export const sumaIdenpendiente = (total) => {
    return (dispatch) => {
        dispatch({
            type: SUMAR_ITEM,
            payload: total
        });
    }
}

export const limpiarCampos = () => {
    return (dispatch) => {
        dispatch({
            type: EMPTY
        })
    }
}

export const limpiarCarrito = () => {
    return (dispatch) => {
        dispatch({
            type: EMPTY_CARRITO
        })
    }
}

export const limpiarItem = () => {
    return (dispatch) => {
        dispatch({
            type: EMPTY_ITEM
        })
    }
}


import React, { useEffect } from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

// Components
import Navbar from '../../layout/Navbar';
import MetodoPay from './MetodoPay';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { hideModal } from '../../actions/modalActions';
import { sumaIdenpendiente } from '../../actions/Items';

// util
import { knowCountry, moneda } from '../../util/bancos';
import { porcentaje } from '../../util/porcentaje';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const Pay = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const estado = useSelector((state) => state.items);
    const states = useSelector((state) => state.auth.allUser)
    const { carrito, productoId, suma } = estado;

    const totalCarrtio = Number(suma).toFixed(2) - Number((porcentaje * suma)).toFixed(2);
    const totalItem = Number(Number(knowCountry(productoId, states)).toFixed(2) * Number(props.location.state).toFixed(2)) - ((Number(knowCountry(productoId, states)).toFixed(2) * Number(props.location.state).toFixed(2)) * Number(porcentaje).toFixed(2));
    const subTotalItem = Number(knowCountry(productoId, states)).toFixed(2) * Number(props.location.state).toFixed(2);

    const table = () => {
        if (carrito.length < 1) {
            if (productoId.length < 1) {
                return null
            } else {
                return (
                    <TableRow key={productoId._id}>
                        <TableCell>{productoId.nombre}</TableCell>
                        <TableCell align="right">{props.location.state}</TableCell>
                        <TableCell align="right">{knowCountry(productoId, states) + moneda(states)}</TableCell>
                        <TableCell align="right">{knowCountry(productoId, states) * props.location.state + moneda(states)}</TableCell>
                    </TableRow>
                )
            }
        } else {
            return carrito.map((row) => (
                <TableRow key={row._id}>
                    <TableCell>{row.nombre}</TableCell>
                    <TableCell align="right">{row.order}</TableCell>
                    <TableCell align="right">{row.precio}</TableCell>
                    <TableCell align="right">{row.order * row.precio}</TableCell>
                </TableRow>
            ))
        }
    }

    const calcularValor = () => {
        if (carrito.length < 1) {
            if (productoId.length < 1) {
                return null
            } else {
                return (
                    <TableCell align="right" key={productoId._id}>
                        {Number(subTotalItem).toFixed(2) + moneda(states)}
                    </TableCell>
                )
            }
        } else {
            return <TableCell align="right">{Number(suma).toFixed(2)}</TableCell>
        }
    }

    const calcularTotal = () => {
        if (carrito.length < 1) {
            if (productoId.length < 1) {
                return null
            } else {
                return (
                    <TableCell align="right" key={productoId._id}>
                        {Number(totalItem).toFixed(2) + moneda(states)}
                    </TableCell>
                )
            }
        } else {
            return <TableCell align="right">{Number(totalCarrtio).toFixed(2)}</TableCell>
        }
    }

    dispatch(hideModal(false));

    useEffect(() => {
        if (productoId.length > 0 && carrito.length < 1) {
            const total = knowCountry(productoId, states) * props.location.state;
            dispatch(sumaIdenpendiente(total));
        }
    }, [productoId, carrito])

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Navbar />
            <Grid
                item
                sm={10}
                className="my-5">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    colSpan={4}
                                    className="font-weight-bold lead">
                                    Detalles de compra
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold lead">Nombre</TableCell>
                                <TableCell
                                    align="right"
                                    className="font-weight-bold lead">Cantidad</TableCell>
                                <TableCell
                                    align="right"
                                    className="font-weight-bold lead">Precio</TableCell>
                                <TableCell
                                    align="right"
                                    className="font-weight-bold lead">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {table()}

                            <TableRow >
                                <TableCell></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} />
                                <TableCell
                                    colSpan={2}
                                    className="font-weight-bold lead">Subtotal</TableCell>
                                {calcularValor()}
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold lead">Desc.</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right">8%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    className="font-weight-bold lead">Total pagar</TableCell>
                                {calcularTotal()}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <MetodoPay
                    cantidad={props.location.state}
                    totalCarrtio={totalCarrtio}
                    totalItem={totalItem} />
            </Grid>
        </Grid >
    );
}

Pay.propTypes = {
    location: PropTypes.object,
}

export default Pay;
import {
    OBTENER_MESSAGE,
    ERROR_OBTENER_MESSAGE,
    DELETE_MESSAGE,
    ERROR_DELETE_MESSAGE,
    UPDATE_MESSAGE,
    ERROR_UPDATE_MESSAGE,
} from '../types';

import axios from '../config/axios';

export const obtenerMessage = (_id) => {
    return async (dispatch) => {
        try {
            const message = await axios.get(`/message/${_id}`);
            dispatch({
                type: OBTENER_MESSAGE,
                payload: message.data.message
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_OBTENER_MESSAGE,
                payload: alert
            })
        }
    }
}

export const deleteMessage = (_id) => {
    return async (dispatch) => {
        try {
            const deleteNoti = axios.delete(`/message/${_id}`);
            dispatch({
                type: DELETE_MESSAGE,
                payload: deleteNoti.data
            });
            obtenerMessage()
        } catch (error) {
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_DELETE_MESSAGE,
                payload: alert
            })
        }
    }
}

export const updateEstado = (_id, data) => {
    return async (dispatch) => {
        try {
            const updateMessage = await axios.put(`/message/${_id}`, data);
            dispatch({
                type: UPDATE_MESSAGE,
                payload: updateMessage.data
            })
        } catch (error) {
            dispatch({
                type: ERROR_UPDATE_MESSAGE,
                payload: error.response.data.msg
            })
        }
    }
}
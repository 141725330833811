import React, { useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Router
import { useHistory } from 'react-router-dom'

// Material
import {
    Grid
} from '@material-ui/core';

// prototype
import PropTypes from 'prop-types'

// Components
import Navbar from '../../layout/Navbar';
import Tab from '../../layout/Tab';

const Perfil = (props) => {

    const usuario = useSelector((state) => state.auth.usuario);

    const history = useHistory();

    useEffect(() => {
        if (!usuario) {
            history.push('/');
        }
    }, [usuario])

    const estado = props.location.state;

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item sm={12}>
                <Navbar />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className="my-5"
            >
                <Grid item sm={10}>
                    <Tab
                        estado={estado} />
                </Grid>
            </Grid>
        </Grid>
    );
}

Perfil.propTypes = {
    props: PropTypes.any,
    location: PropTypes.any
}

export default Perfil;
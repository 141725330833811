import React from 'react';

// Components
import NavbarOpcitional from '../../layout/NavbarOpcitional';
// Material
import {
    Grid,
    Typography,
    Card,
    CardContent,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    margin: {
        margin: theme.spacing(1),
    },
    img: {
        display: "inline",
        width: "20%",
        height: "30%",
        marginTop: "4em"
    },
    font: {
        fontSize: "20em"
    }
}));


const NotFound = () => {

    const classes = useStyles();

    const source = {
        img: "/imagen/itachi.png",
        title: 0
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className="bg-light">
            <NavbarOpcitional />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item sm={12}>
                    <Card className={classes.root}>
                        <CardContent className={"d-flex justify-content-center"}>
                            <Typography
                                variant="h1"
                                align="center"
                                display="inline"
                                className={classes.font}>
                                404
                        </Typography>
                            {/*<img
                                src={source.img}
                                title={source.title}
                                className={classes.img} />
                            <Typography
                                variant="h1"
                                align="center"
                                display="inline"
                                className={classes.font}>
                                4
                            </Typography>*/}
                        </CardContent>
                        <CardContent className={"d-flex justify-content-center"}>
                            <Typography
                                variant="h1"
                                align="center"
                                display="inline">
                                Página no encontrada
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default NotFound;
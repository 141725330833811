import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { autentificacion } from '../actions/authActions';

const RoutePrivate = ({ component: Component, ...props }) => {

    const authentication = useSelector((state) => state.auth.authentification);
    const estado = useSelector((state) => state.items);

    const { carrito, sumaIndent, suma} = estado;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(autentificacion())
        // eslint-disable-next-line
    }, [])

    return (
        <Route
            {...props}
            render={props => !authentication && carrito.length < 1 && sumaIndent === 0 && suma === 0 ? (
                <Redirect to={"/"} />
            ) : (
                    <Component {...props} />
                )}
        />
    )
}

export default RoutePrivate;
import { galeria } from '../util/rutas';
const initialState = {
    charcuteria: [
        {
            images: `${galeria}cafe.jpg`,
            description: "Café",
            price: "150.000",
            id: 4,
        },
        {
            images: `${galeria}ceriales.jpg`,
            description: "Ceriales",
            price: "120.000",
            id: 1,
        },
        {
            images: `${galeria}gallo.jpg`,
            description: "Harinas",
            price: "130.000",
            id: 2,
        },
        {
            images: `${galeria}nestle.png`,
            description: "Niños",
            price: "140.000",
            id: 3,
        },
    ],

    hogar: [{
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(7).jpg",
        description: "Carne",
        price: "120.000",
        id: 1,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg",
        description: "Pollo",
        price: "130.000",
        id: 2,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(11).jpg",
        description: "Salchichas",
        price: "140.000",
        id: 3,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(13).jpg",
        description: "Jamon",
        price: "150.000",
        id: 4,
    }],

    panaderia: [{
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(14).jpg",
        description: "Carne",
        price: "120.000",
        id: 1,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg",
        description: "Pollo",
        price: "130.000",
        id: 2,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(16).jpg",
        description: "Salchichas",
        price: "140.000",
        id: 3,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(17).jpg",
        description: "Jamon",
        price: "150.000",
        id: 4,
    }],

    verduras: [{
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(18).jpg",
        description: "Carne",
        price: "120.000",
        id: 1,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(19).jpg",
        description: "Pollo",
        price: "130.000",
        id: 2,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(20).jpg",
        description: "Salchichas",
        price: "140.000",
        id: 3,
    },
    {
        images: "https://mdbootstrap.com/img/Photos/Slides/img%20(21).jpg",
        description: "Jamon",
        price: "150.000",
        id: 4,
    }]
}

export default (state = initialState, action) => {
    switch (action.type) {
        //case value:

        default:
            return state
    }
};
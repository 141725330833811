import React from 'react';

// MAterial
import {
    Card,
    CardContent,
    Typography,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    pos: {
        marginBottom: 12,
    },
    align: {
        align: "center"
    }
});

const Ubicacion = () => {

    const classes = useStyles();

    return (
        <Card className={classes.root} variant="outlined">
            {/*<CardContent>
                <Typography
                    align="center"
                    variant="h3"
                    color="textSecondary"
                    gutterBottom
                    id="ubicacion"
                    name="ubicacion">
                    Donde nos ubicamos
                </Typography>
                <Typography
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    variant="h6">
                    Jalliday, se ubica en el sector 20 de mayo calle 7, la presente empresa esta dirigida por Grecia
                    Molero, cualquier inquietud por favor no dude en conmunicarte a través de nuestra redes, un saludo
                    de parte de la comunidad de Jalliday.
                </Typography>
            </CardContent>*/}
        </Card>
    );
}

export default Ubicacion;
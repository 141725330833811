import React from 'react';

import { Modal, makeStyles } from '@material-ui/core';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modalActions';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
}));

const ModalPay = () => {
    const classes = useStyles();

    const estado = useSelector((state) => state.modal);

    const { modePay, modal, bancoExterior } = estado;

    const dispatch = useDispatch();

    return (
        <Modal
            open={modal}
            onClose={() => dispatch(hideModal(false))}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={`d-flex justify-content-center mt-5 overflow-auto`}
        >
            <div className={classes.paper}>
                <h2
                    id="simple-modal-title text-center"
                    className="lead font-weight-bold text-center">Métodos de Pago</h2>
                <div className="dropdown-divider"></div>
                <h5 className="lead"> Pago con Tarjeta de Crédito </h5>
                <figure>
                    <img
                        src="/imagen/visa-mastercard.png"
                        alt="Visa & Martercard"
                        className="w-20 mb-5" />
                </figure>

                <h5 className="lead"> Pago con Tarjeta de Débito Nacional </h5>
                <figure className="mb-5">
                    {modePay.map(modePay => (
                        <img
                            src={modePay.img}
                            alt={modePay.title}
                            className={modePay.class}
                            title={modePay.title}
                            key={modePay.id}
                        />
                    ))}
                </figure>

                <h5 className="lead"> Pago con Efectivo </h5>
                <p id="simple-modal-description" className="text-justify mb-5 w-75">
                    Es muy sencillo, cuando necesites realizar una compra puedes cancelar
                    a nuestra sucursal o si realizas algún pedido puedes cancelarle a nuestro
                    operador de Delivery.
                    </p>

                <h5 className="lead"> Transferencia Exteriores </h5>
                <figure className="mb-5">
                    {bancoExterior.map(modeExterior => (
                        <img
                            src={modeExterior.img}
                            alt={modeExterior.title}
                            className={modeExterior.class}
                            title={modeExterior.title}
                            key={modeExterior.id}
                        />
                    ))}
                </figure>
            </div>
        </Modal>
    );
}

export default ModalPay;
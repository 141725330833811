import {
    INPUT_SEARCH,
    GET_PRODUCTO_ID,
    ERROR_PRODUCTO_ID,
    ERROR_INPUT_SEARCH,
    AGREGAR_CARRITO,
    AGREGAR_TOTAL,
    AGREGAR_SUMA,
    UPDATE_ESTADO,
    RESTAR_PRODUCTO,
    SUMAR_ITEM,
    EMPTY,
    EMPTY_CARRITO,
    EMPTY_ITEM,
} from '../types';

const initialState = {
    search: false,
    contextItems: [],
    productoId: [],
    carrito: [],
    suma: 0,
    total: 0,
    sumaIndent: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case INPUT_SEARCH:
            return {
                ...state,
                search: true,
                contextItems: action.payload,
            }
        case GET_PRODUCTO_ID:
            return {
                ...state,
                productoId: action.payload
            }
        case ERROR_INPUT_SEARCH:
        case ERROR_PRODUCTO_ID:
            return {
                ...state,
                search: false,
                contextItems: []
            }
        case AGREGAR_CARRITO:
            return {
                ...state,
                carrito: [...state.carrito, action.payload],
            }
        case AGREGAR_TOTAL:
            return {
                ...state,
                total: action.payload
            }
        case AGREGAR_SUMA:
            return {
                ...state,
                suma: state.suma + action.payload,
            }
        case UPDATE_ESTADO:
            return {
                ...state,
                state: action.payload,
                countCar: Object.keys(state.carrito).length
            }
        case RESTAR_PRODUCTO:
            return {
                ...state,
                suma: state.suma - action.payload
            }
        case SUMAR_ITEM:
            return {
                ...state,
                sumaIndent: action.payload
            }
        case EMPTY:
            return {
                ...state,
                productoId: [],
                carrito: [],
                suma: 0,
                contextItems: [],
                total: 0,
                sumaIndent: 0
            }
        case EMPTY_CARRITO:
            return {
                ...state,
                carrito: [],
                suma: 0,
                contextItems: [],
                total: 0
            }
        case EMPTY_ITEM:
            return {
                ...state,
                productoId: [],
                sumaIndent: 0
            }
        default:
            return state
    }
}
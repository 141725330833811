import React from 'react';

// Material
import { Grid, Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 275,
        marginBottom: "1.5em"
    },
    float_right: {
        position: 'relative',
        float: 'right',
        fontWeight: 300,
    }
}));

const MisDatos = ({ data }) => {

    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item sm={10} className={classes.root}>
                <Card className={classes.root}>
                    <CardContent>
                        <span> <AccountCircleIcon /> </span>
                        <span className={classes.float_right}>{data.nombre} {data.apellido}</span>
                    </CardContent>
                </Card>
                <Card className={classes.root}>
                    <CardContent>
                        <span> <EmailIcon /> </span>
                        <span className={classes.float_right}>{data.email}</span>
                    </CardContent>
                </Card>

                <Typography
                    align='center'
                    variant='h5'
                    className="mt-3"
                    component={"span"}>
                    Dirección de entrega
                </Typography>
                <Card className={classes.root}>
                    <CardContent>
                        {data.direccion ? (
                            <span>
                                <span> <AddLocationIcon /> </span>
                                <span className={classes.float_right}>{data.direccion}</span>
                            </span>
                        ) :
                            (
                                <Typography
                                    align='center'
                                    variant='subtitle'
                                    className="mt-3"
                                    component={"span"}>
                                    Sin dirección
                                </Typography>
                            )}
                    </CardContent>
                </Card>

                <Typography
                    align='center'
                    variant='h5'
                    className="mt-3"
                    component={"span"}>
                    Teléfono
                </Typography>
                <Card className={classes.root}>
                    <CardContent>
                        {data.telefono ? (
                            <span>
                                <span> <PhoneAndroidIcon /> </span>
                                <span className={classes.float_right}>{data.telefono}</span>
                            </span>
                        ) :
                            (
                                <Typography
                                    align='center'
                                    variant='subtitle'
                                    className="mt-3"
                                    component={"span"}>
                                    Sin teléfono
                                </Typography>
                            )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

MisDatos.propTypes = {
    data: PropTypes.object.isRequired
}

export default MisDatos;
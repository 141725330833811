import React from 'react';

// Components
import NavbarOpcitional from '../../layout/NavbarOpcitional';
import CenterSegurity from './CenterSegurity';
import ResolutionProblem from './ResolutionProblem';
import { Grid } from '@material-ui/core';

const Help = () => {

    return (
        <div>
            <NavbarOpcitional />

            <div className="row mt-4 d-flex justify-content-center">
                <p className="lead">
                    Hola. ¿En qué podemos ayudarte?
                </p>
                {/*<Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={7}>
                        <TextField
                            type="search"
                            name="help"
                            id="help"
                            label="Buscar..."
                            placeholder="¿En qué te puedo ayudar?"
                            // onChange={(e) => handleHelp(e.target.value)}
                            variant="outlined"
                            className="bg-light mx-2 my-2 w-100 border border-light" />
                    </Grid>
                </Grid>*/}
            </div>

            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="my-5">
                <CenterSegurity />
                <ResolutionProblem />
            </Grid>
        </div>
    );
}

export default Help;
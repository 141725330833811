import React, { Fragment } from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    makeStyles,
    Divider,
} from '@material-ui/core';
import { phone, correo_pedido } from '../../util/porcentaje';
import NavbarOpcitional from '../../layout/NavbarOpcitional';
import { getCookie } from '../../util/cookie';
import { getParameterByName } from '../../util/rescuperarPassword';
import { useSelector, useDispatch } from 'react-redux';
import { pagOk } from '../../actions/pagoAction';
const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
});

const PaySuccess = () => {
    const classes = useStyles();
    let cookie = getCookie('CODIGO');
    const dispatch = useDispatch();
    const usuario = useSelector((state) => state.auth.usuario);

    const collection_id = getParameterByName('collection_id');
    const collection_status = getParameterByName('collection_status');
    const payment_type = getParameterByName('payment_type');
    const codigo = cookie;
    const dataPaySuccess = {};

    if (collection_id) dataPaySuccess.ref = collection_id;
    if (collection_status) dataPaySuccess.status = collection_status;
    if (payment_type) dataPaySuccess.banco = `mercadoLibre-${payment_type}`;
    if (usuario) dataPaySuccess.usuarios = usuario;
    if (codigo) dataPaySuccess.codigo = codigo;
    dispatch(pagOk(dataPaySuccess));
    
    return (
        <Fragment>
            <NavbarOpcitional />
            <div className="h-100 w-100 gradiente d-flex justify-content-center">
                <Grid item md={4} sm={12}>
                    <Card className={classes.root + ' box-shadow mt-5'}>
                        <CardContent>
                            <Typography variant="h6"
                                align="center"
                                className="lead">
                                <ins>Codigo referencia: {cookie}</ins>
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent>
                            <Typography variant="h6"
                                align="justify"
                                className="lead">
                                Por favor, envienos un capture del pago a través de los siguientes medios &nbsp;
                                {phone} ó {correo_pedido}, recuerde adjuntar su (nombre, apellido, numero de transacción y codigo de referencia de su pedido)
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </div>
        </Fragment>
    );
}

export default PaySuccess;
// Cuentas Bancarias
export const Banesco = {
    cuenta: '0134-0427-50-4271021393',
    cedula: '18.696.781',
    nombre: 'Fabiola',
    apellido: 'Fernández',
}
export const Mercantil = {
    cuenta: '0105-0053-25-1053267320',
    cedula: '5.560.369',
    nombre: 'Miguel Ángel',
    apellido: 'Molero Púlgar',
}
export const Provincial = {
    cuenta: '0108-0515-52-1500002324',
    cedula: '18.962.071',
    nombre: 'Grecia',
    apellido: 'Molero Pacheco',
}
export const Venezuela = {
    cuenta: '0102-0443-73-0100015706',
    cedula: '5.560.369',
    nombre: 'Miguel Ángel',
    apellido: 'Molero Púlgar',
}

export const knowCountry = (item, states) => {
    if (typeof (states) === 'undefined') return null;
    if (!states) return null;
    switch (states.country) {
        case 'AR':
            return item.precio_ar;
        // case 'CL':
        //     return item.precio_ar;
        case 'US':
            return item.precio_eeuu;
        case 'VE':
            return item.precio_vzla;
        default:
            return item.precio_vzla;
    }
}

export const moneda = (states) => {
    if (typeof (states) === 'undefined') return null;
    if (!states) return null;
    switch (states.country) {
        case 'AR':
            return `$`;
        // case 'CL':
        //     return `$CL`;
        case 'US':
            return `$`;
        case 'VE':
            return `$`;
        default:
            return `$`;
    }
}

export const knowCountryOnlyMoney = (item) => {
    if (typeof (item.precio_ar) !== 'undefined') return item.precio_ar;
    if (typeof (item.precio_vzla) !== 'undefined') return item.precio_vzla;
    if (typeof (item.precio_eeuu) !== 'undefined') return item.precio_eeuu;
    if (typeof (item.precio_eeuu) === 'undefined'
        && typeof (item.precio_vzla) === 'undefined'
        && typeof (item.precio_eeuu) === 'undefined') return item.precio;
}
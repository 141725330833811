import React, { useState, Suspense, useEffect } from 'react';

// Material
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

// components
const ModalFinalizarPay = React.lazy(() => import('../modals/ModalFinalizarPay'));
import Paypal from './Paypal';

// actions
import { finalizarPedido } from '../../actions/modalActions';
import { insertarCodigo } from '../../actions/pedidoActions';
import { obtenerPago, obtenerPagoCL } from '../../actions/pagoAction';

// redux
import { useDispatch, useSelector } from 'react-redux';

// Util
import { generarCodigo } from '../../util/randomCodigo';
import {
    transporte,
    phone,
    correo_pedido,
    transporte_arg,
    transporte_us
} from '../../util/porcentaje';
import { moneda } from '../../util/bancos';
import { setCookie } from '../../util/cookie';

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
//selectedValue,
const MetodoPay = ({ cantidad, totalCarrtio, totalItem }) => {

    const dispatch = useDispatch();
    const [estadoPedido, setEstadoPedido] = useState('');
    const [selectedPay, setSelectedPay] = useState('');

    const handleChanges = (event) => {
        setSelectedPay(event.target.value);
    };
    const auth = useSelector((state) => state.auth);
    const estado = useSelector((state) => state.items);
    const mercadoPago = useSelector((state) => state.pago.pagos);
    const { usuario, allUser } = auth;
    const { carrito, suma, productoId } = estado;

    const pruduct = () => {
        if (carrito.length < 1 && suma === 0) return [productoId]
        else return carrito
    }

    const fletes = () => {
        if (estadoPedido === "Si" && allUser.country === 'AR') return transporte_arg;
        if (estadoPedido === "Si" && allUser.country === 'US') return transporte_us;
        if (estadoPedido === "Si" && allUser.country !== 'AR' && allUser.country !== 'US') return transporte
        if (estadoPedido === "No") return 0;
    }

     // valores con porcentaje
     const porItem = Number((totalItem + fletes())).toFixed(2);
     const porCar = Number((totalCarrtio + fletes())).toFixed(2);

    const pagar = () => {
        if (carrito.length < 1 && suma === 0) return porItem;
        else return porCar
    }

    const codigo = {
        codigo: generarCodigo(6),
        usuario: usuario,
        productos: pruduct(),
        total_pagar: pagar(),
        pedido: estadoPedido === "Si" ? true : false,
        cantidad
    }

    const metodosPagos = () => {
        if (allUser.country !== 'US') {
            return (
                <Button variant="contained"
                    color="secondary" onClick={() => botonFinalizar()}>Finalizar Pago</Button>
            )
        } else {
            if (selectedPay === 'PayPal') {
                return <Paypal
                    totalCarrtio={totalCarrtio}
                    totalItem={totalItem}
                    codigo={codigo.codigo}
                    fletes={fletes()} />
            } if (selectedPay === 'Zelle') {
                return <Button variant="contained"
                    color="secondary" onClick={() => botonFinalizar()}>Finalizar Pago</Button>
            }
        }
    }

    const botonFinalizar = () => {
        if (selectedPay.length < 1) return null;
        if (estadoPedido.length < 1) return null;
        if (allUser.country !== 'AR') { // && allUser.country !== 'CL') {
            dispatch(insertarCodigo(codigo));
            dispatch(finalizarPedido(true));
        } else {
            dispatch(insertarCodigo(codigo));
            setCookie('CODIGO', codigo.codigo);
            window.location.assign(mercadoPago);
        }

    }

    const handleChange = (event) => {
        setEstadoPedido(event.target.value);
    };
    useEffect(() => {
        fletes();
        const total_apagar = estadoPedido === 'Si' ? Number(totalItem + transporte_arg) : Number(totalItem);
        const total_Carrito = estadoPedido === 'Si' ? Number(totalCarrtio + transporte_arg) : totalCarrtio;
        if (allUser.country === 'AR') dispatch(obtenerPago(suma === 0 ? total_apagar : total_Carrito, cantidad))
        //if (allUser.country === 'CL') dispatch(obtenerPagoCL(suma === 0 ? total_apagar : total_Carrito, cantidad))
    }, [allUser, estadoPedido])

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">

            <Grid item
                sm={12}>
                <Typography
                    variant="subtitle1"
                    align="center"
                    className="font-weight-bold">
                    Método de pago
                </Typography>
                {allUser.country === 'AR' ?
                    (
                        <div className="d-flex justify-content-center">
                            <FormControlLabel
                                control={<GreenRadio
                                    checked={selectedPay === 'mercado_pago'}
                                    onChange={handleChanges}
                                    value="mercado_pago"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'P' }}
                                />}
                                label="Mercado Pago"
                                className="ml-2">
                            </FormControlLabel>
                        </div>
                    ) : null}
                {/*allUser.country === 'CL' ?
                    (
                        <div className="d-flex justify-content-center">
                            <FormControlLabel
                                control={<GreenRadio
                                    checked={selectedPay === 'mercado_pago'}
                                    onChange={handleChanges}
                                    value="mercado_pago"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'P' }}
                                />}
                                label="Mercado Pago"
                                className="ml-2">
                            </FormControlLabel>
                        </div>
                                ) : null*/}
                {allUser.country === 'US' ?
                    (
                        <div className="d-flex justify-content-center">
                            <FormControlLabel
                                control={<GreenRadio
                                    checked={selectedPay === 'Zelle'}
                                    onChange={handleChanges}
                                    value="Zelle"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'P' }}
                                />}
                                label="Zelle"
                                className="ml-2">
                            </FormControlLabel>
                            {/* <FormControlLabel
                                control={<GreenRadio
                                    checked={selectedPay === 'PayPal'}
                                    onChange={handleChanges}
                                    value="PayPal"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'P' }}
                                />}
                                label="PayPal"
                                className="ml-2">
                            </FormControlLabel> */}
                        </div>
                    ) : null}
                {allUser.country !== 'AR' && allUser.country !== 'US' //&& allUser.country !== 'CL'
                    ?
                    (
                        <div>
                            <div className="d-flex justify-content-center">
                                <FormControlLabel
                                    control={<GreenRadio
                                        checked={selectedPay === 'Banco_Provincial'}
                                        onChange={handleChanges}
                                        value="Banco_Provincial"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'P' }}
                                    />}
                                    label="Banco Provincial"
                                    className="ml-2">
                                </FormControlLabel>
                                <FormControlLabel
                                    control={<GreenRadio
                                        checked={selectedPay === 'Banco_Venezuela'}
                                        onChange={handleChanges}
                                        value="Banco_Venezuela"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'V' }}
                                    />}
                                    label="Banco Venezuela"
                                    className="ml-2">
                                </FormControlLabel>
                                <FormControlLabel
                                    control={<GreenRadio
                                        checked={selectedPay === 'Banco_Mercantil'}
                                        onChange={handleChanges}
                                        value="Banco_Mercantil"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'M' }}
                                    />}
                                    label="Banco Mercantil"
                                    className="ml-2">
                                </FormControlLabel>
                                <FormControlLabel
                                    control={<GreenRadio
                                        checked={selectedPay === 'Banco_Banesco'}
                                        onChange={handleChanges}
                                        value="Banco_Banesco"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />}
                                    label="Banco Banesco">
                                </FormControlLabel>
                            </div>
                        </div>) : null}

                {selectedPay.length !== 'AR' && selectedPay.length > 0 ? (<Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className="mt-3">
                    <Grid item
                        sm={12}>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            className="font-weight-bold">
                            ¿Desea que le llevemos su pedido?
                        </Typography>
                        <div className="d-flex justify-content-center">
                            <FormControlLabel
                                control={<GreenRadio
                                    checked={estadoPedido === 'Si'}
                                    onChange={handleChange}
                                    value="Si"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'S' }}
                                />}
                                label="Si"
                                className="ml-2">
                            </FormControlLabel>
                            <FormControlLabel
                                control={<GreenRadio
                                    checked={estadoPedido === 'No'}
                                    onChange={handleChange}
                                    value="No"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'N' }}
                                />}
                                label="No">
                            </FormControlLabel>
                        </div>
                    </Grid>
                </Grid>) : null}
                <div className="d-flex justify-content-center">
                    {estadoPedido.length > 0 ? metodosPagos() : null}
                </div>
                <Typography variant='subtitle1' align="center">
                    Al
                    finalizar el pago, por favor envienos un foto del pago a través de los siguiente medios:
                    <span className="font-weight-bold">{phone}</span> ó <span className="font-weight-bold">{correo_pedido}</span>
                </Typography>
                {estadoPedido === 'Si' ? <Typography align="center" variant="subtitle1">
                    Al aceptar el envio del pedido, tiene un cargo de {fletes() + moneda(allUser)} adicionales.
                </Typography> : null}
                <Suspense fallback={null}>
                    <ModalFinalizarPay
                        //selectedValue={selectedValue}
                        selectedPay={selectedPay}
                        estadoPedido={estadoPedido}
                        totalItem={estadoPedido === 'Si' ? porItem : totalItem}
                        totalCarrtio={estadoPedido === 'Si' ? porCar : totalCarrtio} />
                </Suspense>
            </Grid>
        </Grid>
    );
}

MetodoPay.propTypes = {
    selectedValue: PropTypes.string,
    cantidad: PropTypes.number,
    totalItem: PropTypes.array,
    totalCarrtio: PropTypes.number
}

export default MetodoPay;
import React, { Fragment } from 'react';

// Material
import { Grid, Typography } from '@material-ui/core';

// Router
import { Link } from 'react-router-dom';

const Politicas = () => {

    return (
        <Fragment>
            <Grid container
                direction="row"
                justify="center"
                alignItems="baseline">
                <Grid item xs={12} sm={4}>
                    <h6 className="h6 ml-5 font-weight-bold mt-5">
                        Conocenos
                    </h6>
                    <ul className="list-group ml-5 mt-3">
                        <li className="list-none mb-2">
                            <Link
                                to={"/what-is-oasis#oasis"}
                                className="text-light h6">
                                ¿Qué es Jalliday?
                            </Link>
                        </li>
                        {/*<li className="list-none mb-2">
                            <a href="/what-is-oasis#ubicacion" className="text-light h6">
                                ¿Donde nos úbicamos?
                            </a>
                        </li>*/}
                        <li className="list-none mb-2">
                            <Link to={"/what-is-oasis#tendencias"} className="text-light h6">
                                ¿Cúales son nuestras Tendencías?
                            </Link>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <h6 className="h6 ml-5 font-weight-bold">
                        Ayuda
                    </h6>
                    <ul className="list-group ml-5 mt-3">
                        <li className="list-none mb-2">
                            <Link to={"/help"} className="text-light h6">
                                Centro de seguridad
                            </Link>
                        </li>
                        <li className="list-none mb-2">
                            <Link to={"/help"} className="text-light h6">
                                Resolución de problemas
                            </Link>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <h6 className="h6 ml-5 font-weight-bold">
                        Servicio al cliente
                    </h6>
                    <ul className="list-group ml-5">
                        <li className="list-none mb-2">
                            <Link to={"/servicio-cliente"}
                                className={" text-light h6 text-light"}>
                                ¿Cómo funciona la Venta telefónica?
                            </Link>
                        </li>
                        <li className="list-none mb-2">
                            <Link to={{
                                pathname: "/servicio-cliente",
                                state: "panel2"
                            }}
                                className={" text-light h6 text-light"}>
                                Métodos y costo de envio
                            </Link>
                        </li>
                        <li className="list-none mb-2">
                            <Link to={{
                                pathname: "/servicio-cliente",
                                state: "panel3"
                            }}
                                className={" text-light h6 text-light"}>
                                Proceso de cancelación de pedido
                            </Link>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                className="mt-5" >
                <Grid item xs={12} sm={12} className="mt-4">
                    <Typography
                        variant="subtitle2"
                        className="mr-2 text-light floar-right d-flex justify-content-end">
                        Términos y condiciones
                        Políticas de privacidad ayuda &nbsp;

                        <cite>
                            Copyright © 2020 Jalliday S.R.L.
                        </cite>
                    </Typography>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Politicas;
import React from 'react';

// Material
import {
    Card,
    CardContent,
    Typography,
    makeStyles
} from '@material-ui/core';

// Util
import { frase } from '../../util/porcentaje';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
});

const Que = () => {

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography
                    align="center"
                    variant="h3"
                    color="textSecondary"
                    gutterBottom
                    id="oasis"
                    name="oasis">
                    {frase}
                </Typography>
                <Typography
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    variant="h6">
                    Jalliday es una compañia encargada de distribuir alimentos en toda Venezuela,
                    la misma esta dividida por varios departamentos. Departamento Hogar,
                    Departamento de Embutidos, Departamento de Contrucción, departamentos de farmacología  entre
                    otros departamentos. La tienda por departamentos es hoy por hoy.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default Que;
import React, { Suspense } from 'react';

// Componenets
import NavbarOpcitional from '../layout/NavbarOpcitional';
import FormNewAccount from './FormNewAccount';
const ModalTerms = React.lazy(() => import('../components/modals/ModalTerms'));
const ModalPrivacidad = React.lazy(() => import('../components/modals/ModalPrivacidad'));

// Material
import {
    Grid,
    Card,
    CardContent,
    makeStyles,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 275,
    },
}));

const NewAccount = () => {

    const classes = useStyles();

    return (
        <div>
            <NavbarOpcitional />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="mt-5">

                <Grid item md={6} sm={12}>
                    <Card className={classes.root + " border border-warning border-bottom-0"} >
                        <Typography
                            align="center"
                            variant="h4"
                            color="textSecondary"
                            className="mt-2"
                        >
                            Crear Cuenta
                        </Typography>
                        <CardContent>
                            <FormNewAccount />
                            <Suspense fallback={null}>
                                <ModalTerms />
                                <ModalPrivacidad />
                            </Suspense>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div >
    );
}

export default NewAccount;
import React, { useState, useEffect, Suspense } from 'react';

// Material
import {
    Grid,
    Typography,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    Paper,
    Link,
} from '@material-ui/core';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import Alert from '@material-ui/lab/Alert';

// Action
import { showRechazarPedidos, showProductos } from '../../actions/modalActions';
import { getPedidos } from '../../actions/pedidoActions';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Components
const ModalRechazarPedido = React.lazy(() => import('../modals/ModalRechazarPedido'));
const ModalProductos = React.lazy(() => import('../modals/ModalProductos'));

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    close: {
        width: "5%",
        position: "relative",
        float: "right",
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Pedidos = () => {

    const classes = useStyles();

    // Redux
    const usuario = useSelector((state) => state.auth.usuario);
    const auth = useSelector(state => state.auth.authentification);
    const pedidos = useSelector(state => state.pedido.pedidos);
    const message = useSelector(state => state.pedido.message);
    const dispatch = useDispatch();

    const [state, setstate] = useState(true)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const SolicitudPedido = (estado) => {
        if (estado === 1) return "Pago pendiente"
        if (estado === 2) return <span className="text-success">Tu pedido ha sido enviado</span>
        if (estado === 3) return <span className="text-danger">Pedido cancelado</span>
        if (estado === 4) return <span className="text-success">Recibido</span>
    }
    const OpcionesPedido = (estado, _id) => {
        if (estado === 1) return <Link className="text-primary decoration"
            onClick={() => handleRechazo(true, _id)}>
            cancelar pedido
            </Link>
        if (estado === 2 || estado === 3 || estado === 4) return <Link className="text-danger decoration">
            Deshabilitado
            </Link>
    }

    const handleRechazo = (estado, _id) => {
        dispatch(showRechazarPedidos(estado, _id));
        setTimeout(() => {
            dispatch(getPedidos(usuario[0]._id));
        }, 2000)
    }

    const handlePedido = (pedido) => {
        dispatch(showProductos(true, pedido))
    }

    const messages = () => {
        if (message && state) {
            return (
                <Alert severity={message.style}>{message.msg}</Alert>
            )
        }
    }

    if (message && state) {
        setTimeout(() => {
            setstate(false);
        }, 4000);
    }

    useEffect(() => {
        if (auth) dispatch(getPedidos(usuario[0]._id));
        // eslint-disable-next-line
    }, [])

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start" >
            <Grid
                item
                sm={9}>
                {messages()}
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="font-weight-bold">Codigo</TableCell>
                                <TableCell align="right" className="font-weight-bold hide">Producto(s)</TableCell>
                                <TableCell align="right" className="font-weight-bold">Total Pagar</TableCell>
                                <TableCell align="right" className="font-weight-bold">Envio</TableCell>
                                <TableCell align="right" className="font-weight-bold">Estado</TableCell>
                                <TableCell align="right" className="font-weight-bold">Acción</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pedidos.length < 1 ? <Typography variant="h6" align="center"> No hay pedidos disponible</Typography> :
                                pedidos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                    <TableRow hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row.codigo}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography align="center">
                                                <Link className="text-primary decoration"
                                                    onClick={() => handlePedido(row)}>
                                                    Ver producto
                                                </Link>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">{row.total_pagar}</TableCell>
                                        <TableCell align="right">{row.pedido ? <DirectionsBikeIcon /> : "No aplica"}</TableCell>
                                        <TableCell align="right">{SolicitudPedido(row.estado)}</TableCell>
                                        <TableCell align="right">{OpcionesPedido(row.estado, row._id)}</TableCell>
                                    </TableRow>
                                )}
                            <Suspense fallback={null}>
                                <ModalRechazarPedido />
                                <ModalProductos />
                            </Suspense>

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={pedidos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid >
    );

}

export default Pedidos;
import {
    OBTENER_MESSAGE,
    ERROR_OBTENER_MESSAGE,
    DELETE_MESSAGE,
    ERROR_DELETE_MESSAGE,
    UPDATE_MESSAGE,
    ERROR_UPDATE_MESSAGE,
} from '../types/index';

const initialState = {
    notificacion: 0,
    massage: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_MESSAGE:
            return {
                ...state,
                notificacion: action.payload
            }
        case ERROR_OBTENER_MESSAGE:
            return {
                ...state,
                notificacion: 0,
                massage: action.payload
            }
        case DELETE_MESSAGE:
        case ERROR_UPDATE_MESSAGE:
        case ERROR_DELETE_MESSAGE:
            return {
                ...state,
                massage: action.payload
            }

        default:
            return state
    }
}
import React, { Fragment } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../actions/modalActions';

// material
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Card, CardContent, Typography } from '@material-ui/core';

// Modals
import ModalPay from '../modals/ModalPay';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
});

const Nav = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const pay = useSelector((state) => state.modepay.modepay)

    return (
        <Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className="mt-4 bg-light px-2 pt-2 w-75 hide-mode-pay"
            >
                {pay.map(resp => (
                    <Grid item md={3} sm={5} key={resp.id}>
                        <Card className={classes.root + " shadow"}>
                            <CardContent>
                                <Typography 
                                component="h5" 
                                variant="h5"
                                align="center">
                                    {resp.title}
                                </Typography>
                                <Typography align="center">
                                    <Button
                                        onClick={() => dispatch(showModal(true))}
                                        className="lead text-primary">
                                        {<resp.icon />}  Más info
                                        </Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <ModalPay />
        </Fragment >
    );
}

export default Nav;
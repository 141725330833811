import React, { useEffect } from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { useDispatch } from 'react-redux';

// Router
import { useHistory } from 'react-router-dom';

// Util
import { getCookie } from '../../util/cookie';
import { knowCountry, moneda } from '../../util/bancos';

// Actions
import { obtenerProducto } from '../../actions/Items';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        marginBottom: "2em"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    detailse: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    img: {
        width: 300,
        height: 180,
        marginLeft: 5,
        marginTop: 5,
        marginBottom: 5
    },
    absolute: {
        position: "absolute",

    },
    buttonFull: {
        width: "100%",
        display: "blod",
    }
}));

const List = ({ items, states }) => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    // cookie
    const cookie = getCookie('LAST_SEARCH');

    const handleItem = async () => {
        await dispatch(obtenerProducto(items._id));
        history.push(`/item/s=${cookie}/&?producto=${items._id}`);
    }

    const EvaluarText = () => {
        if (items.cantidad <= 0) {
            return <h2 className="lead d-flex justify-content-end text-danger font-weight-bold">Producto Agotado</h2>
        } else {
            return <h2 className="lead d-flex justify-content-end text-success font-weight-bold">Precio: {moneda(states) + ' ' + knowCountry(items, states)} </h2>
        }
    }

    const EvaluarButton = () => {
        if (items.cantidad <= 0) {
            return <Button variant="contained" color="secondary" disabled>Agotado</Button>
        } else {
            return <Button variant="contained" className={classes.absolute} onClick={handleItem}> Ver Producto </Button>
        }
    }

    useEffect(() => {
        EvaluarText();
        EvaluarButton();
        knowCountry()
    }, [items, states])

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="center">
            {
                <Grid item sm={12}>
                    <Card className={classes.root}>
                        <div className={classes.details}>
                            <Grid item lg={4} md={4} xs={12} sm={12}>
                                <CardMedia
                                    image={items.urlFile + items.fileName}
                                    title={items.titleFile}
                                    className={classes.img}
                                />
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                className="show-iten">
                                {items.cantidad <= 0 ?
                                    (<Button variant="contained" className="w-100" color="secondary" disabled>Agotado</Button>)
                                    :
                                    (<Button variant="contained" className="w-100" onClick={() => handleItem()}> Ver Producto </Button>)
                                }
                            </Grid>
                        </div>
                        <div className={classes.detailse}>
                            <Grid item
                                lg={12}
                                md={12}
                                xs={12}
                                sm={12}
                                className="float-right hide">
                                <CardContent className={classes.content}>
                                    <header>
                                        <h2 className="lead"> {items.nombre} </h2>
                                        <EvaluarText />
                                        <div className="d-flex justify-content-end">
                                            <EvaluarButton />
                                        </div>
                                    </header>
                                    <p className="lead font-weight-bold">
                                        Características
                                    </p>
                                    <ul className="list-group ml-3">
                                        {
                                            items.length < 1 ? "No aplica" :
                                                items.caracteristicas.map(caracter => (
                                                    <li key={caracter}>
                                                        {caracter}
                                                    </li>
                                                ))
                                        }
                                    </ul>
                                </CardContent>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            }
        </Grid>
    );
}

List.propTypes = {
    items: PropTypes.object,
    states: PropTypes.object
}

export default List;
import React from 'react';

// Material
import {
    Grid,
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
});

const Tendencias = () => {

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className="border-bottom-0">
                <Typography
                    align="center"
                    variant="h3"
                    color="textSecondary"
                    gutterBottom
                    id="tendencias"
                    name="tendencias">
                    Busquedas más habituales
                </Typography>
                <Typography
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    variant="h6">
                    <Grid item md={6} sm={12}>
                        <div className={classes.demo + " justify-content-center d-flex align-items-center"}>
                            <List component="nav" className={classes.root} aria-label="mailbox folders">
                                <ListItem>
                                    <ListItemText
                                        primary="Lampara Led Emergencia 12w 3 Horas Autonomia Frio Mfull - Blanco" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Nutella" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Vodka tofka" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Compacto Max Factor" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Oferta (Paquetes full)" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Desodorante Speed Stick" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Cementos Catatumbo" />
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                </Typography>
            </CardContent>
        </Card>
    );
}

export default Tendencias;
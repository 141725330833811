import {
    GET_CATEGORY,
} from '../types';

const initialState = {
    cateory: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY:
            return {
                ...state,
                cateory: action.payload
            }
    
        default:
            return state;
    }
}
import React, { Fragment } from 'react';

// Material
import Grid from '@material-ui/core/Grid';

// Components
import Que from './Que';
import Ubicacion from './Ubicacion';
import Tendencias from './Tendencias';

// Components
import NavbarOpcitional from '../../layout/NavbarOpcitional';

const WhatOasis = () => {

    return (
        <Fragment>
            <NavbarOpcitional />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className="mt-5"
            >
                <Grid item sm={12}>
                    <Que />
                    <Ubicacion />
                    <Tendencias />
                </Grid>
            </Grid>
        </Fragment >
    );
}

export default WhatOasis;
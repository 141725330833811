import {
    OBTENER_PAGOS,
    ERROR_OBTENER_PAGOS,
    OBTENER_PAGO,
    ERROR_OBTENER_PAGO,
    OBTENER_PAGO_CHILE,
    ERROR_OBTENER_PAGO_CHILE,
} from '../types';

import axios from '../config/axios';

export const obtenerPagos = (user) => {
    return async (dispatch) => {
        try {
            const api = await axios.get(`pago/pago/${user}`);
            dispatch({
                type: OBTENER_PAGOS,
                payload: api.data
            });
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error",
            };
            dispatch({
                type: ERROR_OBTENER_PAGOS,
                payload: alert
            })
        }
    }
}

export const obtenerPago = (pago, cantidad) => {
    return async (dispatch) => {
        const pagos = {
            total_pagar: pago,
            cantidad
        }
        try {
            const pago = await axios.post(`/mercado-pago`, pagos);
            dispatch({
                type: OBTENER_PAGO,
                payload: pago.data
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error",
            };
            dispatch({
                type: ERROR_OBTENER_PAGO,
                payload: alert
            })
        }
    }
}

export const obtenerPagoCL = (pago, cantidad) => {
    return async (dispatch) => {
        const pagos = {
            total_pagar: pago,
            cantidad
        }
        try {
            const pago = await axios.post(`/mercado-pago/chile`, pagos);
            dispatch({
                type: OBTENER_PAGO_CHILE,
                payload: pago.data
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error",
            };
            dispatch({
                type: ERROR_OBTENER_PAGO_CHILE,
                payload: alert
            })
        }
    }
}

export const pagOk = (data) => {
    return async (dispatch) => {
        try {
            await axios.post(`pago/`, data);
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error",
            };
            dispatch({
                type: ERROR_OBTENER_PAGO_CHILE,
                payload: alert
            })
        }
    }
}
import React, { useState } from 'react';

import {
    makeStyles,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Input
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// components
import NavbarOpcitional from '../layout/NavbarOpcitional';

// Util
import { mail } from '../util/ValidarCampos';
import { phone } from '../util/porcentaje';

// Router
import { Link } from 'react-router-dom'

// Redux
import { useDispatch, useSelector } from 'react-redux';

// actions
import { recuperarEmail } from '../actions/authActions';

const useStyles = makeStyles((theme) => ({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    cursor: {
        cursor: "pointer"
    },
    close: {
        width: "5%",
        position: "relative",
        float: "right",
    },
}));

const ResetPassword = () => {

    const [state, setstate] = useState('');
    const [alerterror, setAlertError] = useState(false);
    const [alertsuccess, setAlertSuccess] = useState(false);

    const classes = useStyles();

    const dispatch = useDispatch();

    const mensaje = useSelector((state) => state.auth.mensaje);

    const onSubmit = (e) => {
        e.preventDefault();

        if (!mail.test(state)) {
            setAlertError(true);
            return null;
        }

        if (dispatch(recuperarEmail(state))) {
            setAlertSuccess(true);
            setTimeout(() => {
                setAlertSuccess(false);
            }, 10000);
        }
    }


    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={12}>
                <NavbarOpcitional />
            </Grid>
            <Grid item md={6} sm={12}>
                <Card>
                    <CardContent>
                        <Typography
                            id="transition-modal-title"
                            color="textSecondary"
                            align="center"
                            variant="h4"
                            gutterBottom>
                            ¿Cómo recuperar mi contraseña?
                        </Typography>
                        <Typography
                            id="transition-modal-description"
                            className={classes.pos}
                            align="center"
                            variant="subtitle1"
                            color="textSecondary">
                            Para recuperar tu contraseña por favor ingresa tu
                            email de la cuenta asociada.
                        </Typography>

                        {alertsuccess ?
                            (<Alert severity={mensaje ? mensaje.style : ''}>
                                {mensaje ? mensaje.msg : <Typography
                                    variant="subtitle2"
                                    className="lead"
                                    align="center">Loading...</Typography>}</Alert>)
                            : null}

                        {alerterror ?
                            (<Alert severity="error">El E-Mail no cumple con los requisitos</Alert>)
                            : null}

                        <form noValidate
                            autoComplete="off"
                            onSubmit={onSubmit}>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel htmlFor="name">E-Mail</InputLabel>
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Ingrese su E-Mail"
                                        className="text-center mb-2"
                                        required
                                        value={state}
                                        onChange={(e) => setstate(e.target.value)}
                                    />

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="mb-2">
                                        Restablecer Contraseña
                                    </Button>
                                </FormControl>
                            </Grid>
                        </form>
                        <Typography
                            id="transition-modal-description"
                            className={classes.pos}
                            align="center"
                            variant="subtitle1"
                            color="textSecondary">
                            Por favor consulta tu bandeja de span.
                                        <br />
                            <br />
                                    Sí el E-Mail ingresado no se encuentra asocionado a ninguna cuenta
                                    <br />
                                               no podrás recibir el mail para recuperar tu contraseña, en tal caso,
                                        <br /> que no recuerdes el E-Mail asociado puedes consulta al siguiente
                                        <br /> número {phone}. Recuerda que puedes crear una
                                        <br />
                            <Link to={"/newAccount"}>
                                nueva cuenta
                            </Link>.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default ResetPassword;
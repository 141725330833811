import React from 'react';

//Materal
import { Grid } from '@material-ui/core';

// Components
import Sesion from './Sesion';
import Category from './Category';
import SendAllPlace from './SendAllPlace';

const Menus = () => {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
        >
            <Grid item sm={2} className="hide">
                <SendAllPlace />
            </Grid>

            <Grid item sm={10} className="responsive-design-btn" >
                <Category />
                <Sesion />
            </Grid>

        </Grid>
    );
}

export default Menus;
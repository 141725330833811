import React, { useState, useEffect } from 'react';

// Components
import Navbar from '../../layout/Navbar';
import Items from './Items';
import SideBar from '../../layout/SideBar';
import Footer from '../../layout/Footer';
import Spinner from '../spinner/Spinner';

// Material
import { Grid } from '@material-ui/core';

// Reux
import { useSelector } from 'react-redux';

const List = () => {
    const items = useSelector((state) => state.items.contextItems);
    const state = useSelector(state => state.auth.allUser);

    const [spinner, handleSpinner] = useState(true)

    const timeSpinner = () => {
        setTimeout(() => {
            handleSpinner(false)
        }, 3000)
    };

    useEffect(() => {
        timeSpinner();
    }, [spinner, state])

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item lg={12}
                md={12}
                xs={12}
                sm={12}
                className="mb-4">
                <Navbar />
            </Grid>
            <Grid item lg={3} md={3} xs={12} sm={12}>
                <SideBar />
            </Grid>
            <Grid item lg={9} md={9} xs={12} sm={12}>

                <header className="mb-5">
                    <h2>
                        {items.length < 1 ?
                            (<span>No hay resultados <Spinner spinner={spinner} />  </span>) :
                            (<span>Resultados de la busqueda </span>)}
                    </h2>
                </header>

                {items.map(items =>
                    <Items
                        key={items._id}
                        items={items}
                        states={state}
                    />
                )}
            </Grid>

            <Grid item sm={12} className=" w-100 overflow-auto">
                <Footer />
            </Grid>
        </Grid>
    );
}

export default List;
import React from 'react';

import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

const Logo = () => {
    return (
        <Grid item sm={2}>
            {/**
             * Esta parte de aca es el logo
             */}
            <Link to={"/"}>
                <figure>
                    <img
                        src="/imagen/logotipo.png"
                        alt="Jalliday"
                        width="250" />
                    {/*<figcaption
                        className="text-muted lead h7 font-weight-bold ml-5 hide">
                        Oasis
                    </figcaption>*/}
                </figure>
            </Link>
        </Grid>
    );
}

export default Logo;
import { combineReducers } from 'redux';

import modalReducer from './modalReducer';
import slideReducer from './slideReducer';
import modePayReducer from './modePayReducer';
import authReducer from './authReducer';
import listProductReducer from './listProductReducer';
import itemsReducers from './itemsReducers';
import pedidoReducers from './pedidoReducers';
import messageReducers from './messageReducers';
import pagoReducer from './pagoReducer';
import comunidadReducers from './comunidadReducers';
import categoriaReducers from './categoriaReducers';

// Se utiliza combineReducers, porque solo por aplicacion se debe tener un state
// Entonces se definen varios reducers se combinen con esta funcion (combineReducers)
// se le envia al store y recibe los reducer

export default combineReducers({
    modal: modalReducer,
    charcuteria: slideReducer,
    modepay: modePayReducer,
    auth: authReducer,
    listSlideProducto: listProductReducer,
    items: itemsReducers,
    pedido: pedidoReducers,
    message: messageReducers,
    pago: pagoReducer,
    comunidad: comunidadReducers,
    categoria: categoriaReducers
})
import React from 'react';

// Material
import {
    Grid,
    Typography,
} from '@material-ui/core';

// proptypes
import PropTypes from 'prop-types';

// component
import Navbar from '../../layout/Navbar';
import Acordion from '../../layout/Acordion';

// Util
import { frase } from '../../util/porcentaje';

const ServiceCliente = (props) => {

    const estado = props.location.state;
    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item sm={12} >
                <Navbar />
            </Grid>
            <Grid item sm={12} >
                <Typography
                    variant="h5"
                    align="center"
                    className="my-3">
                    <span className="lead">Servicio al cliente {frase}</span>
                </Typography>
            </Grid>
            <Grid item sm={8} xs={12} className="my-2">
                <Acordion
                    estado={estado} />
            </Grid>
        </Grid>
    );
}

ServiceCliente.propTypes = {
    props: PropTypes.any,
    location: PropTypes.any,
}

export default ServiceCliente;
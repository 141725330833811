import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { autentificacion } from '../actions/authActions';

const RoutePrivate = ({ component: Component, ...props }) => {

    const authentication = useSelector((state) => state.auth.authentification);
    const restaurar = useSelector((state) => state.auth.restaurar);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(autentificacion())
        // eslint-disable-next-line
    }, [])

    return (
        <Route
            {...props}
            render={props => authentication && restaurar ? (
                <Redirect to={"/"} />
            ) : (
                    <Component {...props} />
                )}
        />
    )
}

export default RoutePrivate;
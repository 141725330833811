import React from 'react';

// Router
import { useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Material
import { Menu, MenuItem } from '@material-ui/core';

// Actions
import { hideModal } from '../../actions/modalActions';
import { closeSession } from '../../actions/authActions';

const ModalAuth = () => {

    const modalAuth = useSelector((state) => state.modal.modalAuth);
    const dispatch = useDispatch();

    const history = useHistory();
    const handlePerfil = () => {
        dispatch(hideModal(false));
        history.push('/perfil');
    }

    const handleLogout = () => {
        dispatch(closeSession());
        dispatch(hideModal(false));
        history.push('/');
    }
    return (
        <Menu
            id="simple-menu"
            anchorEl={modalAuth}
            keepMounted
            open={Boolean(modalAuth)}
            onClose={() => dispatch(hideModal(false))}
        >
            <MenuItem onClick={() => handlePerfil()}>Mi Perfil</MenuItem>
            <MenuItem onClick={() => handleLogout()}>Salir</MenuItem>
        </Menu>
    );
}

/*const lazyLoadingModalAuth = () => {
    const [show, setstate] = useState(false);
    const elementoRef = useRef()

    useEffect(() => {
        let elemento = document.getElementById("componentLazyLoading");

        const onClick = (entries, observer) => {
            const el = entries[0];
            if (el.isIntersecting) {
                setstate(true);
                observer.disconnect();
            }
        }

        const observer = new IntersectionObserver(onClick, {
            rootMargin: '5px'
        });

        observer.observe(elementoRef.current)

        return () => observer.disconnect()
    }, []);

    return (<div ref={elementoRef}>
        {show ? <ModalAuth /> : null}
    </div>)


}*/

export default ModalAuth;
import React, {useState} from 'react';

// material
import {
    Snackbar,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import TwitterIcon from '@material-ui/icons/Twitter';
import Alert from '@material-ui/lab/Alert';

// util
import { instagram, facebook, twettir, pinterest } from '../../util/rutas';
import {mail} from '../../util/ValidarCampos'

// reducers
import {useDispatch, useSelector} from 'react-redux';

// actions
import {setComunidad} from '../../actions/comunidadActions';

const Redes = () => {

    // Redux
    const dispatch = useDispatch();
    const state = useSelector(state => state.comunidad.message);

    // Estado Local
    const [statemail, setstate] = useState({
        email: ''
    });
    const {email} = statemail;
    const [enunciado, SetEnunciado] = useState('');
    const [open, setOpen] = useState(false);

    //Operaciones
    const handleEnter = (e) => {
        if(!mail.test(email)){
            SetEnunciado("Para ser parte de nuestra comunidad ingrese su E-Mail");
            setOpen(true);
            return null;
        }
        if(e.key  === 'Enter'){
            dispatch(setComunidad(statemail));
            setOpen(true);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleStado = (e) => {
        setstate({ 
            ...statemail, 
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="mt-5">

            <h6 className="lead text-center font-weight-bold">
                suscribete a comunidad de Jalliday
                </h6>

            <p className="font-size text-justify">
                Si te suscribes a nuestra red podrás recibir notificaciones
                y ofertas de nuestra comunidad.
                </p>

            <TextField
                type="email"
                name="email"
                id="email"
                label="Correo electronico"
                placeholder="Ingresa tu dirección de correo"
                value={email}
                onChange={(e) => handleStado(e)}
                onKeyDown={(e) => handleEnter(e)}
                variant="outlined"
                className="bg-light mx-2 my-2 w-100 border border-light" />

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info">
                        {state ? state : enunciado}
                    </Alert>
                </Snackbar>

            <div className="text-justify ml-5 mt-4 mb-3">
                <a href={instagram} alt="Instagram"> <InstagramIcon className="mr-3 hover-instagran" /> </a>
                <a href={facebook} alt="Facebbok"> <FacebookIcon className="mr-3 hover-facebook" /> </a>
                <a href={pinterest} alt="Pinterest"> <PinterestIcon className="mr-3 hover-pintarest" /> </a>
                <a href={twettir} alt="Twetter"> <TwitterIcon className="hover-twetter" /> </a>
            </div>
        </div>
    );
}

export default Redes;
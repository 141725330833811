import React, { Fragment, useRef } from 'react';

import { Divider } from '@material-ui/core';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modalActions';
import { closeSession } from '../../actions/authActions';

// Router
import { Link, useHistory } from 'react-router-dom';

const Ambusguesa = () => {
    const dispatch = useDispatch();
    const authentification = useSelector((state) => state.auth.authentification);

    const ref = useRef();
    const refMenu = useRef();

    const history = useHistory();
    const handleLogout = () => {
        dispatch(closeSession());
        dispatch(hideModal(false));
        history.push('/');
    }

    const toggleSidenav = (e) => {

        ref.current.classList.toggle('is-active');
        refMenu.current.classList.toggle("is_active");
        e.preventDefault();
    }



    return (
        <Fragment>
            <div className="hamburger" ref={ref} onClick={(e) => toggleSidenav(e)}>
                <div className="_layer -top"></div>
                <div className="_layer -mid"></div>
                <div className="_layer -bottom"></div>
            </div>
            <nav className='menuppal' ref={refMenu}>
                <ul>
                    {!authentification ?
                        (
                            <Fragment>
                                <li>
                                    <Link to={"/account"}>Iniciar Sesión</Link>
                                </li>
                                <li>
                                    <Link to={"/newAccount"}>Crear Cuenta</Link>
                                </li>
                            </Fragment>
                        )
                        :
                        (
                            <Fragment>
                                <li>
                                    <Link to={"/perfil"}>Mi perfil</Link>
                                </li>
                                <li>
                                    <Link to={{
                                        pathname: "/perfil",
                                        state: 3,
                                    }}>Mis Compras</Link>
                                </li>
                            </Fragment>
                        )
                    }
                    <li>
                        <Link to={"/servicio-cliente"}>Servicio al Cliente</Link>
                    </li>
                    <li>
                        <Link to={"/help"}>Ayuda</Link>
                    </li>

                    {!authentification ? null :
                        <Fragment>
                            <Divider />
                            <li>
                                <Link onClick={() => handleLogout()}>Cerrar Sesión</Link>
                            </li>
                        </Fragment>}
                </ul>
            </nav>
        </Fragment>
    );
}

export default Ambusguesa;
import {
    INSERTAR_CODIGO,
    ERROR_INSERTAR_CODIGO,
    OBTENER_PEDIDO,
    ERROR_OBTENER_PEDIDO,
    MOTIVO_EXITO,
    ERROR_MOTIVO,
} from '../types';

const initialState = {
    codigo: null,
    message: null,
    pedidos: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case INSERTAR_CODIGO:
            return {
                ...state,
                codigo: action.payload,
            }
        case ERROR_INSERTAR_CODIGO:
            return {
                ...state,
                message: action.payload,
            }
        case OBTENER_PEDIDO:
            return {
                ...state,
                pedidos: action.payload,
            }
        case ERROR_OBTENER_PEDIDO:
            return {
                ...state,
                message: action.payload,
            }
        case MOTIVO_EXITO:
            return {
                ...state,
                message: action.payload,
            }
        case ERROR_MOTIVO:
            return {
                ...state,
                message: action.payload,
            }

        default:
            return state
    }
}
export const setCookie = (cname, cvalue) => {
    var expires = "expires=max-age=60";
    var dominio = 'jalliday.com';
    document.cookie = `${cname}=${cvalue};${expires};path=/;${dominio}`;
}

export const getCookie = (nombre) => {
    var lista = document.cookie.split(";");
    for (var i in lista) {
        var busca = lista[i].search(nombre);
        if (busca > -1) { var micookie = lista[i] }
    }
    //if(micookie.indexOf("=") === -1) return null;
    if (typeof (micookie) === "undefined") return null;
    var igual = micookie.indexOf("=");

    var valor = micookie.substring(igual + 1);
    return valor;
}
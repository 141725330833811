import React, { useState, useEffect } from 'react';

// Actions
import { login } from '../actions/authActions';

// Router
import { Link, useHistory } from 'react-router-dom';

// Material
import {
    Grid,
    FormControl,
    InputLabel,
    Input,
    Button,
    Card,
    CardContent,
    makeStyles,
    Typography
} from '@material-ui/core';

import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// RegEx
import { pass, mail } from '../util/ValidarCampos';

// Alert
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const FormAccount = ({ perfils }) => {

    const classes = useStyles();

    // Redux
    const authentification = useSelector((state) => state.auth.authentification);
    const usuario = useSelector((state) => state.auth.mensaje);
    const dispatch = useDispatch();

    // Router
    const history = useHistory();

    const [inputValues, handleInput] = useState({
        email: '',
        password: '',
    });

    const [empty, fieldEmpty] = useState(false);
    const [statePass, NoCumplePass] = useState(false);
    const [stateMail, NoCumpleMail] = useState(false);

    const { email, password } = inputValues;

    const handleField = (e) => {
        if (email.length < 1 && email.password < 1) return null;

        handleInput({
            ...inputValues,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (email.length < 1 || password.length < 1) {
            fieldEmpty(true);
            return null;
        }

        if (!pass.test(password)) {
            fieldEmpty(false);
            NoCumplePass(true);
            return null;
        }

        if (pass.test(password)) NoCumplePass(false);

        if (!mail.test(email)) {
            NoCumpleMail(true);
            return null;
        }

        if (mail.test(email)) NoCumpleMail(false);

        if (dispatch(login(inputValues))) {
            if (perfils) {
                perfils = false;
                //window.location.reload(true);
                history.push({
                    pathname: '/perfil',
                    state: 2
                })
            }
            setTimeout(() => {

            }, 3000)
        }
    }

    useEffect(() => {
        if (authentification)
            history.push('/');
    }, [authentification])

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="mt-5"
        >
            <Grid item md={6} sm={12}>

                {usuario ?
                    (<Alert severity={usuario.style}>{usuario.msg}</Alert>)
                    : null}
                {empty ?
                    (<Alert severity="warning">Por favor, complete todos los campos</Alert>)
                    : null}
                <Card className={classes.root + " border border-warning"}>
                    <CardContent>
                        <Typography
                            align="center"
                            variant="h4"
                            color="textSecondary"
                        >
                            Iniciar Sesión
                            </Typography>
                        <form
                            onSubmit={onSubmit}
                            className="my-3">
                            {stateMail ?
                                (<Alert severity="error">El E-Mail no cumple con los requisitos</Alert>)
                                :
                                null}
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel
                                    htmlFor="email"
                                    className="lead">
                                    Correo Electronico:
                                    </InputLabel>
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Ingrese su correo electronico*"
                                    onChange={handleField}
                                    value={email}
                                    required />
                            </FormControl>

                            {statePass ?
                                (<Alert severity="error">La contraseña no cumple con los requisitos</Alert>)
                                :
                                null}

                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel
                                    htmlFor="password"
                                    className="lead">
                                    Contraseña:
                                    </InputLabel>
                                <Input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="(Mínimo 8 caracteres, letras (mayúsculas y minúsculas), numeros y caracteres espaciales)*"
                                    onChange={handleField}
                                    value={password}
                                    required />
                            </FormControl>

                            <Grid item xs={12} className="mt-2">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="mb-2 w-100">
                                    Iniciar Sesión
                                    </Button>
                            </Grid>

                            <Link to="/newAccount">
                                ¿Aún no tienes cuenta? Registrate gratis
                            </Link>

                            <Link to="/resetPassword"
                            className="d-inline float-right">
                                Olvide mi contraseña
                            </Link>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

FormAccount.propTypes = {
    perfils: PropTypes.bool,
}

export default FormAccount;
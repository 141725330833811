import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const initialState = {
    modepay: [{
        title: "Tarjeta Crédito",
        icon: CreditCardIcon,
        id: 1,
    },
    {
        title: "Tarjeta de débito",
        icon: CreditCardIcon,
        id: 2,
    },
    {
        title: "Efectivo",
        icon: LocalAtmIcon,
        id: 3,
    },
    {
        title: "Transferencia",
        icon: AttachMoneyIcon,
        id: 4,
    },]
}

export default (state = initialState, action) => {
    switch (action.type) {

        default:
            return state;
    }
}
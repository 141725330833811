import {
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_MODAL_AUTH,
    SHOW_MODAL_TERMS,
    SHOW_MODAL_PRIVACIDAD,
    SHOW_MODAL_ACCOUNT,
    SHOW_UPDATE_ACCOUNT,
    SHOW_LIST_ITEM,
    SHOW_MODAL_CARRITO,
    SHOW_FINALIZAR_PAGO,
    SHOW_CANCELAR_PEDIDO,
    SHOW_PAGO_EXTERIOR,
    SHOW_PROBLEMAS_PEDIDOS,
    SHOW_RECHAZO_PEDIDOS,
    SHOW_PRODUCTOS,
} from '../types';

import { imagen } from '../util/rutas';

const initialState = {
    modal: false,
    modalAuth: false,
    modalTerms: false,
    modalAccount: false,
    modalUpdateAccount: false,
    modalItem: false,
    modalCarrito: false,
    modalFinalizarPago: false,
    modalCancelarPedido: false,
    modalPagoExterior: false,
    modalProblemasPedidos: false,
    modalRechazoPedido: false,
    modalProductos: false,
    modalprivacidad: false,
    rechazo: null,
    pedidos: null,
    modePay: [{
        img: `${imagen}venezuela.jpg`,
        title: "Banco Venezuela",
        class: "logo-bank mr-3",
        id: 2,
    },
    {
        img: `${imagen}bod.png`,
        title: "Banco b.o.d",
        class: "logo-bank mr-3",
        id: 3,
    },
    {
        img: `${imagen}banesco.png`,
        title: "Banco Banesco",
        class: "logo-bank mr-3",
        id: 5,
    },
    {
        img: `${imagen}mercantil.jpg`,
        title: "Banco Mercantil",
        class: "logo-bank mr-3",
        id: 6,
    }],

    bancoExterior: [
    {
        img: `${imagen}mercadoPago.jpg`,
        title: "Mercado Pago",
        class: "logo-bank mr-3",
        id: 2,
    },
    /*{
        img: `${imagen}rut.jpg`,
        title: "Banco Estado",
        class: "logo-bank mr-3",
        id: 4,
    },*/
    {
        img: `${imagen}zelle.jpg`,
        title: "Zelle",
        class: "logo-bank mr-3",
        id: 5,
    }],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modal: action.payload,
            }
        case HIDE_MODAL:
            return {
                ...state,
                modal: action.payload,
                modalAuth: action.payload,
                modalTerms: action.payload,
                modalAccount: action.payload,
                modalUpdateAccount: action.payload,
                modalItem: action.payload,
                modalCarrito: action.payload,
                modalFinalizarPago: action.payload,
                modalCancelarPedido: action.payload,
                modalPagoExterior: action.payload,
                modalProblemasPedidos: action.payload,
                modalRechazoPedido: action.payload,
                modalProductos: action.payload,
                modalprivacidad: action.payload,
                rechazo: null,
                pedidos: null,
            }
        case SHOW_MODAL_AUTH:
            return {
                ...state,
                modalAuth: action.payload,
            }
        case SHOW_MODAL_TERMS:
            return {
                ...state,
                modalTerms: action.payload,
            }
        case SHOW_MODAL_PRIVACIDAD: 
            return {
                ...state,
                modalprivacidad: action.payload,
            }
        case SHOW_MODAL_ACCOUNT:
            return {
                ...state,
                modalAccount: action.payload,
            }
        case SHOW_UPDATE_ACCOUNT:
            return {
                ...state,
                modalUpdateAccount: action.payload,
            }
        case SHOW_LIST_ITEM:
            return {
                ...state,
                modalItem: action.payload,
            }
        case SHOW_MODAL_CARRITO:
            return {
                ...state,
                modalCarrito: action.payload,
            }
        case SHOW_FINALIZAR_PAGO:
            return {
                ...state,
                modalFinalizarPago: action.payload,
            }
        case SHOW_CANCELAR_PEDIDO:
            return {
                ...state,
                modalCancelarPedido: action.payload,
            }
        case SHOW_PAGO_EXTERIOR:
            return {
                ...state,
                modalPagoExterior: action.payload,
            }
        case SHOW_PROBLEMAS_PEDIDOS:
            return {
                ...state,
                modalProblemasPedidos: action.payload,
            }
        case SHOW_RECHAZO_PEDIDOS:
            return {
                ...state,
                modalRechazoPedido: action.payload.modal,
                rechazo: action.payload._id
            }
        case SHOW_PRODUCTOS:
            return {
                ...state,
                modalProductos: action.payload.modal,
                pedidos: action.payload.pedidos
            }

        default:
            return state
    }
};
import {
    DATA_AUTH,
    DATA_AUTH_ERROR,
    LOGIN,
    LOGIN_ERROR,
    OBTENER_USUARIO,
    CREAR_CUENTA,
    ERROR_CUENTA,
    CLOSE_SESSION,
    MODIFICAR_USUARIO,
    ERROR_MODIFICAR_USUARIO,
    RECUPERAR_EMAIL,
    ERROR_RECUPERAR_EMAIL,
    EXITO_PASSWORD,
    ERROR_RECUPERAR_PASSWORD,
    RECUPERAR_PASSWORD,
    CHANGE_RECUPERAR_PASSWORD,
} from '../types';

const initialState = {
    token: localStorage.getItem('token'),
    authentification: null,
    allUser: null,
    mensaje: null,
    carga: true,
    restaurar: true,
    usuario: null,
    updateUsuario: [],
    confirm: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_AUTH:
            return {
                ...state,
                allUser: action.payload
            }
        case OBTENER_USUARIO:
            return {
                ...state,
                authentification: true,
                carga: false,
                mensaje: null,
                usuario: [action.payload]
            }
        case LOGIN:
        case CREAR_CUENTA:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                token: action.payload,
                authentification: true,
                mensaje: null,
                carga: false,
            }

        case ERROR_CUENTA:
        case LOGIN_ERROR:
        case CLOSE_SESSION:
        case ERROR_MODIFICAR_USUARIO:
        case ERROR_RECUPERAR_EMAIL:
        case RECUPERAR_EMAIL:
        case DATA_AUTH_ERROR:
        case ERROR_RECUPERAR_PASSWORD:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                authentification: false,
                mensaje: action.payload,
                usuario: null,
            }

        case MODIFICAR_USUARIO:
            return {
                ...state,
                usuario: [...state.usuario, action.payload]
            }

        case EXITO_PASSWORD:
            return {
                ...state,
                confirm: action.payload
            }
        case RECUPERAR_PASSWORD:
            return {
                ...state,
                restaurar: action.payload
            }
        case CHANGE_RECUPERAR_PASSWORD:
            return {
                ...state,
                restaurar: action.payload
            }

        default:
            return state;
    }
}
import React, {useRef, useState, useEffect} from 'react';

// Components
import Politicas from '../components/Footer/Politicas';
import Redes from '../components/Footer/Redes';

import Grid from '@material-ui/core/Grid';

const Footer = () => {
    return (
        <Grid
            container
            direction="row"
            className="bg-dark romper"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item md={4} sm={6} className="text-light">
                <Redes />
            </Grid>
            <Grid item md={8} sm={6} className="text-light">
                <Politicas />
            </Grid>
        </Grid>
    );
}

const lazyLoadingModalAuth = () => {
    const [show, setstate] = useState(false);
    const elementoRef = useRef()

    useEffect(() => {
        const onClick = (entries, observer) => {
            const el = entries[0];
            if (el.isIntersecting) {
                setstate(true);
                observer.disconnect();
            }
        }

        const observer = new IntersectionObserver(onClick, {
            rootMargin: '100px'
        });

        observer.observe(elementoRef.current)

        return () => observer.disconnect()
    }, []);

    return (<div ref={elementoRef}>
        {show ? <Footer /> : null}
    </div>)
}

export default lazyLoadingModalAuth;
import {
    SET_COMUNIDAD,
    ERROR_SET_COMUNIDAD
    } from '../types';
import axios from '../config/axios';

export const setComunidad = (email) => {
    return async (dispatch) => {
        try {
            const api = await axios.post('comunidad/', email)
            dispatch({
                type: SET_COMUNIDAD,
                payload: api.data.msg
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            dispatch({
                type: ERROR_SET_COMUNIDAD,
                payload: error.response.data.msg,
            })
        }
    }
}
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { autentificacion } from '../actions/authActions';
import PropTypes from 'prop-types';

const ErrorRoute = ({ component: Component, ...props }) => {

    const authentication = useSelector((state) => state.auth.authentification);
    const loading = useSelector((state) => state.auth.carga);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(autentificacion())
        // eslint-disable-next-line
    }, [])

    return (
        <Route
            {...props}
            render={props => !authentication && loading ? (
                <Redirect to="/" />
            ) : (
                    <Component {...props} />
                )}
        />
    )
}

ErrorRoute.propTypes = {
    component: PropTypes.any
}

export default ErrorRoute;
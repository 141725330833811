import React from 'react';

// Material
import {
    Grid,
    makeStyles,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Button,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { deleteMessage, obtenerMessage, updateEstado } from '../../actions/messageActions';

// Util
import { fecha } from '../../util/fecha';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderColor: "#6c757d!important",
        border: "1px solid #dee2e6!important",
        marginBottom: "1em",
    },
    back: {
        backgroundColor: "rgba(76, 165, 240, .3 )",
        cursor: "pointer"
    },
    white: {
        backgroundColor: "#FFF",
        cursor: "pointer"
    }
}));

const Message = ({ noti }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const usuario = useSelector((state) => state.auth.usuario);
    // const message = useSelector((state) => state.message.notificacion);

    const deleteMessages = (id) => {
        if (id) dispatch(deleteMessage(id));
        setTimeout(() => {
            dispatch(obtenerMessage(user))
        }, 2000)
    }

    const handleState = (id, data) => {
        dispatch(updateEstado(id, data));
        setTimeout(() => {
            dispatch(obtenerMessage(user))
        }, 2000)
    }

    const user = usuario ? usuario[0]._id : null;
    const view = {
        estado: false
    }

    return (
        <List
            className={classes.root, noti.estado ? classes.back : classes.white}
            onClick={() => handleState(noti._id, view)}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <NotificationsIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    className="font-weight-bold"
                    primary={noti.titulo}
                    secondary={noti.mensaje + "\n Fecha: " + fecha(noti.fecha)} />
                <Grid container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => deleteMessages(noti._id)}>
                        <DeleteIcon />
                    </Button>
                </Grid>
            </ListItem>
        </List>
    );
}

Message.propTypes = {
    noti: PropTypes.object,
}

export default Message;
//Acciones
import {
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_MODAL_AUTH,
    SHOW_MODAL_TERMS,
    SHOW_MODAL_PRIVACIDAD,
    SHOW_MODAL_ACCOUNT,
    SHOW_UPDATE_ACCOUNT,
    SHOW_LIST_ITEM,
    SHOW_MODAL_CARRITO,
    SHOW_FINALIZAR_PAGO,
    SHOW_CANCELAR_PEDIDO,
    SHOW_PAGO_EXTERIOR,
    SHOW_PROBLEMAS_PEDIDOS,
    SHOW_RECHAZO_PEDIDOS,
    SHOW_PRODUCTOS,
} from '../types';

export const showModal = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL,
            payload: modal,
        })
    }
}

export const hideModal = (modal) => {
    return (dispatch) => {
        dispatch({
            type: HIDE_MODAL,
            payload: modal,
        })
    }
}

export const showModalAuth = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL_AUTH,
            payload: modal,
        })
    }
}

export const showModalTerms = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL_TERMS,
            payload: modal,
        })
    }
}

export const showModalPrivacidad= (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL_PRIVACIDAD,
            payload: modal,
        })
    }
}

export const showModalAccount = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL_ACCOUNT,
            payload: modal,
        })
    }
}

export const showModalUpdateCuenta = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_UPDATE_ACCOUNT,
            payload: modal,
        })
    }
}

export const showModalItem = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_LIST_ITEM,
            payload: modal,
        })
    }
}

export const finalizarPedido = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_FINALIZAR_PAGO,
            payload: modal,
        })
    }
}

export const showModalCarrito = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL_CARRITO,
            payload: modal,
        })
    }
}

export const showCancelarPedido = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CANCELAR_PEDIDO,
            payload: modal,
        })
    }
}

export const showPagoExterior = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_PAGO_EXTERIOR,
            payload: modal,
        })
    }
}

export const showPProblemasPedidos = (modal) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_PROBLEMAS_PEDIDOS,
            payload: modal,
        })
    }
}

export const showRechazarPedidos = (modal, _id) => {
    return (dispatch) => {
        const opcions = {
            modal,
            _id,
        }
        dispatch({
            type: SHOW_RECHAZO_PEDIDOS,
            payload: opcions,
        })
    }
}

export const showProductos = (modal, pedidos) => {
    return (dispatch) => {
        const opcions = {
            modal,
            pedidos,
        }
        dispatch({
            type: SHOW_PRODUCTOS,
            payload: opcions,
        })
    }
}
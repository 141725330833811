import React, { useEffect, useState } from 'react';

// Material
import {
    Grid,
    Card,
    CardContent,
    Breadcrumbs,
    Typography,
    InputLabel,
    Button,
    Snackbar,
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Alert from '@material-ui/lab/Alert';

// Component
import Navbar from '../../layout/Navbar';
import Items from './Items';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Router
import { Link, useHistory } from 'react-router-dom'

// Actions
import {
    addCarrito,
    sumas,
    totals,
    updateEstado,
    limpiarCarrito,
    obtenerProducto
} from '../../actions/Items';

// Util
import { getCookie } from '../../util/cookie';
import { getParameterByName } from '../../util/rescuperarPassword';
import { knowCountry, moneda } from '../../util/bancos';

// Prop
import PropTypes from 'prop-types';

const Item = ({ states }) => {

    // Router
    const history = useHistory();

    // redux
    const dispatch = useDispatch();
    const producto = useSelector(state => state.items.productoId);
    const estado = useSelector((state) => state.items);
    const auth = useSelector((state) => state.auth.authentification);
    const { carrito, contextItems } = estado;

    console.log(contextItems)

    // Estado Local
    const [contar, setContar] = useState(1)
    //const [value, setValue] = useState(Math.floor((Math.random() * (11 - 2)) + 2));
    //const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    // util
    const cookie = getCookie('LAST_SEARCH');

    // Operaciones
    const handleCompra = () => {
        if (!auth) {
            history.push('/account');
            return null;
        }
        dispatch(limpiarCarrito())
        history.push({
            pathname: '/pay',
            state: contar
        });
    }

    const sumProducts = (array) => {
        var total = 0
        array.forEach(product => total += product.order)
        dispatch(totals({ total }))
    }

    const handleAddProduct = (indexCart, indexProduct) => {
        var statusCopy = Object.assign({}, estado);
        if (statusCopy.contextItems[indexProduct].cantidad > 1) {
            statusCopy.carrito[indexCart].total += statusCopy.carrito[indexCart].precio
            statusCopy.carrito[indexCart].order += contar
            statusCopy.contextItems[indexProduct].cantidad -= contar
            dispatch(updateEstado(statusCopy));
            sumProducts(statusCopy.carrito);
        } else {
            alert('Producto inexistente')
        }
    }

    const handleCloses = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCarrito = (id) => {
        if (!auth) {
            history.push('/account')
        }
        setOpen(true)
        let producto = contextItems.find(producto => producto._id === id);
        let indexProducto = contextItems.findIndex(index => index._id === producto._id);

        var statusCopy = Object.assign({}, estado);

        var ListProducto = {
            _id: producto._id,
            nombre: producto.nombre,
            marca: producto.marca,
            precio: knowCountry(producto, states),
            order: contar,
            total: knowCountry(producto, states) * contar,
            img: producto.img,
        }

        var exist = carrito.find(p => p._id === id);
        if (undefined !== exist && exist !== null) {
            let indexCart = carrito.findIndex(x => x._id === exist._id);
            handleAddProduct(indexCart, indexProducto);
            dispatch(sumas(ListProducto));
        } else {
            var statusCopy = Object.assign({}, estado);
            statusCopy.contextItems[indexProducto].cantidad -= contar;
            sumProducts(statusCopy.carrito);
            //sumTotal(statusCopy.carrito);
            dispatch(sumas(ListProducto))
            dispatch(addCarrito(ListProducto));
        }
    }

    useEffect(() => {
        const name = getParameterByName('producto');
        dispatch(obtenerProducto(name))
    }, [])
    return (
        <div className="bg-light">

            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Navbar />
            </Grid>

            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start">
                <Grid item md={8} className="my-3">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Link color="inherit" to={`/list-items/${cookie}`}>
                            Lista productos
                        </Link>
                        <Typography color="textPrimary">{producto.nombre}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start">
                <Grid item md={5} sm={6}>
                    <Card className="modal-ext ml-5">
                        <CardContent className="img-contenedor">
                            <img src={producto.urlFile + producto.fileName}
                                alt={producto.titleFile}
                                className="size-imagen d-block m-auto" />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={5} sm={6}>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="flex-start">
                        <Grid item md={12} sm={12}>
                            <Typography
                                align="justify"
                                variant="h6"
                                className="px-2 lead font-weight-light font-italic">
                                {producto.marca}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <Typography
                                align="justify"
                                variant="h6"
                                className="px-2 lead">
                                {producto.nombre}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <Typography
                                align="justify"
                                variant="subtitle1"
                                className="px-2 lead mt-3">
                                {moneda(states) + ' ' + knowCountry(producto, states)}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        align="center"
                                        variant="subtitle1"
                                        className="px-2 lead mt-3 font-weight-bold">
                                        Propiedades
                                    </Typography>
                                    {typeof (producto.caracteristicas) === 'undefined' || producto.caracteristicas.length < 1 ? null :
                                        producto.caracteristicas.map(resp => (
                                            <li className="lead ml-2" key={resp}>{resp}</li>
                                        ))}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={12} className="mt-2">
                            <InputLabel htmlFor="cantidad" className="d-inline lead" >Cantidad: </InputLabel>
                            <input
                                type="text"
                                name="cantidad"
                                id="cantidad"
                                className="form-control w-50 d-inline"
                                value={contar}
                                max={producto.cantidad}
                                min={1} />

                            <Button variant="contained"
                                onClick={() => setContar(contar < 2 ? contar : contar - 1)}
                                className="h3 font-weight-bold my-1 btn-tab p-1">-</Button>
                            <Button variant="contained"
                                onClick={() => setContar(contar < producto.cantidad ? contar + 1 : contar)}
                                className="h5 font-weight-bold my-1 btn-tab p-1">+</Button>
                        </Grid>
                        <Grid item sm={12} className="my-3">
                            <Button
                                variant="contained"
                                className="btn-large"
                                onClick={() => handleCompra()}>
                                Comprar
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="btn-large"
                                onClick={() => handleCarrito(producto._id)}>
                                <ShoppingCartIcon />
                            </Button>
                        </Grid>
                        <Grid item sm={12} className="mt-3">
                            <Card className="bg-dark">
                                <CardContent>
                                    <Typography align="center"
                                        variant="subtitle1"
                                        className="text-light font-weight-bold">
                                        Aprovecha nuestro descuento del 8% por tu compra
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="mt-5">
                <Card className="modal-ext">
                    <CardContent>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1"
                                align="justify"
                                className="lead font-weight-bold">
                                Más productos de esta Categoría
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {contextItems.length < 1 ? null :
                                <Items
                                    key={contextItems[0]._id}
                                    items={contextItems[0]}
                                    states={states}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {contextItems.length < 2 ? null :
                                <Items
                                    key={contextItems[1]._id}
                                    items={contextItems[1]}
                                    states={states}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {contextItems.length < 3 ? null :
                                <Items
                                    key={contextItems[2]._id}
                                    items={contextItems[2]}
                                    states={states}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {contextItems.length < 4 ? null :
                                <Items
                                    key={contextItems[3]._id}
                                    items={contextItems[3]}
                                    states={states}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {contextItems.length < 5 ? null :
                                <Items
                                    key={contextItems[4]._id}
                                    items={contextItems[4]}
                                    states={states}
                                />
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => handleCloses()}>
                <Alert onClose={() => handleCloses()} severity="success">
                    Su producto se ha agregado al carrito.
                </Alert>
            </Snackbar>
        </div>
    );
}

Item.propTypes = {
    states: PropTypes.object
}

export default Item;
import React from 'react';

const SendAllPlace = () => {
    return (
        <figure>
            <img
                src="/imagen/ubicacion.png"
                alt="Jalliday"
                width="25"
                className="float-left" />
            <figcaption
                className="text-muted lead h7 font-weight-bold">Envíos a toda Venezuela</figcaption>
        </figure>
    );
}

export default SendAllPlace;
import {
    GET_CATEGORY,
} from '../types';

import axios from '../config/axios';

export const get_categoria = () => {
    return async (dispatch) => {
        const categoria = await axios.get('categoria/');
        dispatch({
            type: GET_CATEGORY,
            payload: categoria.data
        })
    }
}
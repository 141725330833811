import React, { Fragment } from 'react';

// Components
import Nav from './pay_mode/Nav';
import Slide from '../layout/Slide';
import Cosmeticos from './promotions/Cosmeticos';
import Hogar from './promotions/Cafeteria';
import Panaderia from './promotions/HiegieneLimpieza';
import Verduras from './promotions/Higiene';
import Footer from '../layout/Footer';
// import Categoria from './promotions/Categoria';
import Navbar from '../layout/Navbar';

// MAterial
import Grid from '@material-ui/core/Grid';

const LoadingPage = () => {
    return (
        <Fragment>
            <Navbar />

            <Grid item sm={12}>
                <Slide />
            </Grid>

            <Grid item sm={12} className="d-flex justify-content-center">
                <Nav />
            </Grid>

            <Grid item sm={12}>
                {/* <Categoria /> */}
            </Grid>

            <Grid item sm={12}>
                <Cosmeticos />
            </Grid>

            <Grid item sm={12}>
                <Hogar />
            </Grid>

            <Grid item sm={12}>
                <Panaderia />
            </Grid>

            <Grid item sm={12}>
                <Verduras />
            </Grid>

            <Grid item sm={12}>
                <Footer />
            </Grid>
        </Fragment>
    );
}

export default LoadingPage;
import React, { useState, useEffect } from 'react';

// Router
import { Link, useHistory } from 'react-router-dom'

// Material
import {
    Grid,
    FormControl,
    InputLabel,
    Input,
    Typography,
    Button,
    makeStyles,
} from '@material-ui/core';

// Alert
import Alert from '@material-ui/lab/Alert';

// Redux
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { showModalTerms, showModalPrivacidad } from '../actions/modalActions';
import { crear_cuenta } from '../actions/authActions';

// Util
import { pass, mail } from '../util/ValidarCampos';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    cursor: {
        cursor: "pointer"
    }
}));

const FormNewAccount = () => {

    const classes = useStyles();
    const data = {};

    const dispatch = useDispatch();
    const usuario = useSelector((state) => state.auth.mensaje);
    const auth = useSelector((state) => state.auth);
    const { authentification, allUser } = auth;
    const history = useHistory();

    const [camposVacios, handleCamposVacios] = useState(false);
    const [statePass, NoCumplePass] = useState(false);
    const [stateMail, NoCumpleMail] = useState(false);

    const [inputValues, handleInput] = useState({
        nombre: '',
        apellido: '',
        email: '',
        password: '',
    })

    const { nombre, apellido, email, password } = inputValues;

    const handleField = (e) => {
        handleInput({
            ...inputValues,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (nombre.length < 1 ||
            apellido.length < 1 ||
            email.length < 1 ||
            password.length < 1) {
            handleCamposVacios(true);
            return null;
        }

        if (nombre.length > 1 ||
            apellido.length > 1 ||
            email.length > 1 ||
            password.length > 1) {
            handleCamposVacios(false);
        }

        if (!pass.test(password)) {
            NoCumplePass(true);
            return null;
        }

        if (!mail.test(email)) {
            NoCumpleMail(true);
            return null;
        }

        handleInput({
            ...inputValues,
            ip: allUser.ip,
            country: allUser.country,
            city: allUser.city
        });

        data.nombre = nombre;
        data.apellido = apellido;
        data.email = email;
        data.password = password;
        data.ip = allUser.ip;
        data.country = allUser.country;
        data.city = allUser.city;

        if (dispatch(crear_cuenta(data))) {
            NoCumplePass(false);
            history.push('/');
        }
    }

    useEffect(() => {
        if (authentification) history.push('/');
    }, [authentification, allUser])

    return (
        <form
            onSubmit={onSubmit}
            className="my-5"
            noValidate
            autoComplete="off">
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item sm={12}>
                    {usuario ?
                        (<Alert severity={usuario.style}>{usuario.msg}</Alert>)
                        : null}
                    {camposVacios ?
                        (<Alert severity="warning">Por favor, complete todos los campos</Alert>)
                        : null}
                </Grid>
                <Grid item
                    md={6}
                    sm={12}
                    className="w-100">
                    <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="nombre">Nombre(s)</InputLabel>
                        <Input
                            type="text"
                            id="nombre"
                            name="nombre"
                            placeholder="Ingresa tu(s) nombre(s)*"
                            required
                            onChange={handleField}
                            value={nombre} />
                    </FormControl>
                </Grid>

                <Grid item
                    md={6}
                    sm={12}
                    className="w-100">
                    <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="apellido">Apellido(s)</InputLabel>
                        <Input
                            type="text"
                            id="apellido"
                            name="apellido"
                            placeholder="Ingresa tu(s) apellido(s)*"
                            required
                            onChange={handleField}
                            value={apellido} />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className="my-3" >

                <Grid item sm={12} className="mb-3">
                    {stateMail ?
                        (<Alert severity="error">El E-Mail no cumple con los requisitos</Alert>)
                        :
                        null}
                    <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                            id="email"
                            label="E-Mail"
                            type="email"
                            name="email"
                            placeholder="Ingresa tu correo electronico*"
                            required
                            onChange={handleField}
                            value={email} />
                    </FormControl>
                </Grid>

                <Grid item sm={12}>
                    {statePass ?
                        (<Alert severity="error">La contraseña no cumple con los requisitos</Alert>)
                        :
                        null}
                    <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="password">Contraseña</InputLabel>
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="(Mínimo 8 caracteres, letras (mayúsculas y minúsculas), numeros y caracteres espaciales)*"
                            required
                            onChange={handleField}
                            value={password} />
                    </FormControl>
                </Grid>
            </Grid>

            <Typography
                variant="body2"
                align="justify"
                className="lead h7">
                Al registrarte, aceptas el
                <a className={classes.cursor}
                    onClick={() => dispatch(showModalPrivacidad(true))}>
                    <span className="text-primary">
                        Aviso de Privacidad
                    </span>
                </a>,los
                <a
                    className={classes.cursor}
                    onClick={() => dispatch(showModalTerms(true))}>
                    <span className="text-primary decoration">
                        Términos y Condiciones
                    </span>
                </a> y el envío de noticias
                    y promociones exclusivas. Puedes desactivar notificaciones
                    en `Preferencias`.
            </Typography>

            <Button
                type="submit"
                variant="contained"
                className="mb-2 w-100">
                Crear Cuenta
            </Button>
            <Link to="/account">
                Ya tengo cuenta
            </Link>
        </form>
    );
}

export default FormNewAccount;
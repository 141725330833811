import React, { Fragment, useEffect, useState } from 'react';

// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Material
import { MenuItem, IconButton, Grid } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

// Actions
import { showModalAuth } from '../../actions/modalActions';

// components
import Amburguesa from './Amburguesa';
import ModalAuth from '../modals/ModalAuth';

// Util
import { phone } from '../../util/porcentaje';

const BtnAuth = () => {

    const authentification = useSelector((state) => state.auth.authentification);
    const usuario = useSelector((state) => state.auth.usuario);
    const dispatch = useDispatch();

    const [user, handleUsuario] = useState('');

    const handleAuth = (e) => {
        dispatch(showModalAuth(e.currentTarget))
    }

    useEffect(() => {
        if (!usuario) null
        else handleUsuario(usuario[0].nombre);
    }, [usuario])

    return (
        <Fragment>
            {/**
             * Esta parte de aca es el whatsapp
             */}
            <Grid item md={4} sm={4} >
                <span className="lead h7 mt-2 hide">
                    Has tu pedido por whatsapp
                </span>
                <a href={`https://api.whatsapp.com/send?phone=${phone}&text=hola%20Jalliday`}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                        className="text-success hover-whatsapp mx-0 whatsapp-responsive">
                        <WhatsAppIcon />
                    </IconButton>
                </a>
                {!authentification ? null : (
                    <MenuItem className="float-right mb-3 hide btn-auth"
                        onClick={(e) => handleAuth(e)}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit">
                            <AccountCircle /> <span className="lead"> &nbsp; {user}</span>
                        </IconButton>
                    </MenuItem>
                )}
            </Grid>
            <span>
                <ModalAuth />
            </span>
            <Grid container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
            >
                <Grid item sm={1} >
                    <Amburguesa />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default BtnAuth;
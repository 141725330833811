import React from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    makeStyles,
} from '@material-ui/core';
import { imagen } from '../../util/rutas';
import NavbarOpcitional from '../../layout/NavbarOpcitional';
const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
});

const PaySuccess = () => {
    const classes = useStyles();
    return (
        <Grid>
            <NavbarOpcitional />
            <div className="h-100 w-100 gradiente d-flex justify-content-center">
                <Grid item
                    md={4}
                    sm={12}>
                    <img src={`${imagen}advertencia.png`} alt="error" />
                    <Card className={classes.root + ' box-shadow'}>
                        <CardContent>
                            <Typography variant="h6"
                                align="justify"
                                className="lead">
                                    Ha ocurrido un error en el proceso de pago, por favor vuelva a intentarlo.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </div>
        </Grid>
    );
}

export default PaySuccess;
import React from 'react';
import ReactDOM from 'react-dom';
import paypal from 'paypal-checkout';
import { phone, correo_soporte } from '../../util/porcentaje';
import PropTypes from 'prop-types';

const Paypal = ({ totalCarrtio, totalItem, codigo, fletes }) => {

    const paypalConf = {
        currency: 'USD',
        env: 'production',
        client: {
            sandbox: '--',
            production: 'AV-ofC6KNxNVH2ytjsz6TSItf4-rFlCiwD_8KAPoFI37ss9ZJnuOaH7V81upxvSpJp8pbiI032ukPCVj',
        },
        style: {
            label: 'pay',
            size: 'medium', // small | medium | large | responsive
            shape: 'rect',   // pill | rect
            color: 'gold',  // gold | blue | silver | black
        },
    };

    const PayPalButton = paypal.Button.driver('react', { React, ReactDOM });

    const payment = (data, actions) => {
        const payment = {
            transactions: [
                {
                    amount: {
                        total: totalCarrtio === 0 || typeof (totalCarrtio) === 'undefined' ? totalItem[0] + fletes : totalCarrtio + fletes,
                        currency: paypalConf.currency,
                    },
                    description: 'Carrito de Compra Jalliday',
                    custom: codigo,
                },],
            note_to_payer: `Contactanos para cualquier aclaracion a través de los siguientes medios ${phone} o ${correo_soporte}`,
        };

        // console.log(payment);
        return actions.payment.create({
            payment,
        });
    };

    /*const payment = (data, actions) => {
        const payment = {
            transactions: [{
                amount: {
                    total: totalCarrtio === 0 || typeof (totalCarrtio) === 'undefined' ? totalItem[0] : totalCarrtio,
                    currency: paypalConf.currency
                },
                description: 'Carrito Compra de Jalliday',
                custom: codigo,
            }],
            note_to_player: 
            };
        return actions.payment.create({ payment })
    };*/

    const onAuthorize = (data, actions) => {
        return actions.payment.execute()
            .then(response => {
                console.log(response);
                alert(`El Pago fue procesado correctamente, ID: ${response.id}`)
            })
            .catch(error => {
                console.log(error);
                alert('Ocurrió un error al procesar el pago con Paypal');
            });
    };

    const onError = (error) => {
        alert('El pago con PayPal no fue realizado, vuelva a intentarlo.');
    };

    const onCancel = (data, actions) => {
        alert('El pago con PayPal no fue realizado, se ha canceló el proceso.');
    };

    return (
        <PayPalButton
            env={paypalConf.env}
            client={paypalConf.client}
            payment={(data, actions) => payment(data, actions)}
            onAuthorize={(data, actions) => onAuthorize(data, actions)}
            onCancel={(data, actions) => onCancel(data, actions)}
            onError={(error) => onError(error)}
            style={paypalConf.style} y

            commit
            locale="es_AR"/>
    )
}
// return (
//     <Grid
//         container
//         direction="row"
//         justify="flex-start"
//         alignItems="flex-start">

//     </Grid>
// );
Paypal.propTypes = {
    totalCarrtio: PropTypes.number,
    totalItem: PropTypes.array,
    codigo: PropTypes.string,
    fletes: PropTypes.func
}

export default Paypal;
import React, { useState, useEffect, useRef } from 'react';

// Carousel
import ItemsCarousel from 'react-items-carousel';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Router
import { useHistory } from 'react-router-dom';

// Actions
import { inputSeach } from '../../actions/Items';

// Components
import { Grid, makeStyles, GridListTileBar, IconButton } from '@material-ui/core';

// util
import { setCookie } from '../../util/cookie';

const useStyles = makeStyles(() => ({
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    pointer: {
        cursor: 'pointer'
    },
}));

const Higiene = () => {

    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;

    const list = useSelector((state) => state.listSlideProducto.Farmacos_y_medicamentos);

    const handleEmbutidos = (search) => {
        setCookie("LAST_SEARCH", search);
        dispatch(inputSeach());
        const variable = `s=${search}`
        history.push(`/list-items/${variable}`)
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <div className="bg-light w-100 py-5 mb-2 h-especial text-center mt-3 hide">
                <header>
                    <h1 className={"lead text-center text-uppercase text-dark py-3 " + classes.bgcolor}>
                        Departamento de Fármacos y Medicamentos
                    </h1>
                </header>
                <div style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={3}
                        gutter={20}
                        leftChevron={<span className="h4 text-light">{'<'}</span>}
                        rightChevron={<span className="h4 text-light">{'>'}</span>}
                        outsideChevron
                        chevronWidth={chevronWidth}>
                        {list.map(embutidos => (
                            <a onClick={() => handleEmbutidos(embutidos.link)}
                                alt={embutidos.description}
                                key={embutidos.id}
                                className={classes.pointer}>
                                <img src={embutidos.img} 
                                className="h-100 w-100" 
                                alt={embutidos.link} />
                                <GridListTileBar
                                    title="Departamento de Fármacos y Medicamentos"
                                    actionIcon={
                                        <IconButton
                                            aria-label={`info about ${embutidos.link}`}
                                            className={classes.icon}>
                                        </IconButton>
                                    }
                                />
                            </a>
                        ))}
                    </ItemsCarousel>
                </div>
            </div>
        </Grid>
    );
}

const lazyLoadingModalAuth = () => {
    const [show, setstate] = useState(false);
    const elementoRef = useRef()

    useEffect(() => {
        const onClick = (entries, observer) => {
            const el = entries[0];
            if (el.isIntersecting) {
                setstate(true);
                observer.disconnect();
            }
        }

        const observer = new IntersectionObserver(onClick, {
            rootMargin: '100px'
        });

        observer.observe(elementoRef.current)

        return () => observer.disconnect()
    }, []);

    return (<div ref={elementoRef}>
        {show ? <Higiene /> : null}
    </div>)
}

export default lazyLoadingModalAuth;
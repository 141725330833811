import React, { useState, useEffect } from 'react';

// Material
import {
    Grid,
    Typography,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';

// Action
import { obtenerPagos } from '../../actions/pagoAction';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Util
import { fecha } from '../../util/fecha';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    close: {
        width: "5%",
        position: "relative",
        float: "right",
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Pedidos = () => {

    const classes = useStyles();

    // Redux
    const usuario = useSelector((state) => state.auth.usuario);
    const auth = useSelector(state => state.auth.authentification);
    const pago = useSelector(state => state.pago.pago);
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (auth) dispatch(obtenerPagos(usuario[0]._id));
        // eslint-disable-next-line
    }, [])

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start" >
            <Grid
                item
                sm={9}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="font-weight-bold">Referencia de pago</TableCell>
                                <TableCell align="right" className="font-weight-bold">Instituto bancaria</TableCell>
                                <TableCell align="right" className="font-weight-bold">Total pagado</TableCell>
                                <TableCell align="right" className="font-weight-bold">Fecha de pago</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pago.length < 1 ? <Typography variant="h6" align="center"> No hay pagos disponible</Typography> :
                                pago.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row.referencia}
                                        </TableCell>
                                        <TableCell align="right">{row.banco}</TableCell>
                                        <TableCell align="right">{row.total_pagado}</TableCell>
                                        <TableCell align="right">{fecha(row.fecha)}</TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={pago.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid >
    );

}

export default Pedidos;
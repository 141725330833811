import React, { Suspense } from 'react';

// MAterial
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';

// Redux
import { useDispatch } from 'react-redux'; showCancelarPedido

// Actions
import { showCancelarPedido, showPagoExterior } from '../../actions/modalActions';
//showPProblemasPedidos
// Compoenents
const ModalCancelarPedido = React.lazy(() => import('../modals/ModalCancelarPedido'));
const ModalPagarExterior = React.lazy(() => import('../modals/ModalPagarExterior'));
//const ModalProblemasPedidos = React.lazy(() => import('../modals/ModalProblemasPedidos'));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


const ResolutionProblem = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Grid item xs={12} sm={4}>
            <List component="nav" className={classes.root} aria-label="mailbox folders">
                <ListItem>
                    <ListItemText primary="Resolución de problemas" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => dispatch(showCancelarPedido(true))}>
                    <ListItemText
                        className="text-primary decoration"
                        primary="¿Cómo cancelar mi pedido?" />
                </ListItem>
                <Divider />
                <ListItem
                    button
                    divider
                    onClick={() => dispatch(showPagoExterior(true))}>
                    <ListItemText
                        className="text-primary decoration"
                        primary="¿Cómo pagar mi pedido desde el exterior?" />
                </ListItem>
                {/*<ListItem
                    button
                onClick={() => dispatch(showPProblemasPedidos(true))}
                >
                    <ListItemText
                        className="text-primary decoration" />
                </ListItem>*/}
                <Divider light />
            </List>
            <Suspense fallback={null}>
                <ModalCancelarPedido />
                <ModalPagarExterior />
                {/*<ModalProblemasPedidos />*/}
            </Suspense>
        </Grid>
    );
}

export default ResolutionProblem;
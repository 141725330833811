import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: "2em"
    },
    roots: {
        flexGrow: 1,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    detailse: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    img: {
        width: 300,
        height: 180,
        marginLeft: 5,
        marginTop: 5,
        marginBottom: 5
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    absolute: {
        position: "absolute",

    },
    buttonFull: {
        width: "100%",
        display: "blod",
    }
}));

const List = () => {

    const classes = useStyles();

    return (
        <Grid row spacing={2}>
            <header className="mb-5">
                <h2>
                    Ofertas del día
                </h2>
            </header>
            <Card className={classes.root}>
                <div className={classes.details}>
                    <Grid lg={4} md={4} item xs={12} sm={12}>
                        <CardMedia
                            image="https://mdbootstrap.com/img/Photos/Slides/img%20(10).jpg"
                            title="Live from space album cover"
                            className={classes.img}
                        />
                        <Button variant="contained" size='large' className={classes.buttonFull + " meddiambutton"}  >
                            Ver Producto
                                    </Button>
                    </Grid>
                </div>
                <div className={classes.detailse}>
                    <Grid lg={12} md={12} item xs={12} sm={12} className="float-right context-hide">
                        <CardContent className={classes.content}>
                            <header>
                                <h2 className="lead"> Nombre del Producto</h2>
                                <h2 className="lead d-flex justify-content-end"> Precio: 120$</h2>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" className={classes.absolute} >
                                        Ver Producto
                                    </Button>
                                </div>
                            </header>
                            <ul className="list-group ml-3 ">
                                <li>
                                    Propiedades #1
                                </li>
                                <li>
                                    Propiedades #2
                                </li>
                                <li>
                                    Propiedades #3
                                </li>
                            </ul>

                        </CardContent>
                    </Grid>
                </div>
            </Card>
        </Grid>
    );
}

export default List;
//Redes
export const instagram = "https://www.instagram.com/jallidayca8/";
export const facebook = "https://www.facebook.com/Jallidayca/";
export const pinterest = "https://ar.pinterest.com/jallidayca/";
export const twettir = "https://twitter.com/Jallidayca/";

// galeria
export const galeria = "/imagen/galeria/";

// imagen
export const imagen = "/imagen/";
export const bandera = "/imagen/banderas/";
import React, { useEffect } from 'react';

// Components
import Search from '../components/Navbar/Search';
import Menus from '../components/Navbar/Menu';

import Grid from '@material-ui/core/Grid';

import { useDispatch, useSelector } from 'react-redux';

import { autentificacion } from '../actions/authActions';

const Navbar = () => {

    const dispatch = useDispatch();

    const usuario = useSelector(state => state.auth.usuario)
    const auth = useSelector(state => state.auth.authentification)

    useEffect(() => {
        if (auth && !usuario) dispatch(autentificacion());
    }, [usuario, auth])

    return (
        <div className="bg-warning-pastel p-3">
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Search />
            </Grid>

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Menus />
            </Grid>
        </div>
    );
}

export default Navbar;
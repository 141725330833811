import React, { useState } from 'react';

import {
    Grid,
    Card,
    CardContent,
    Typography,
    TextField,
    makeStyles,
    FormControl,
    InputLabel,
    Input,
    Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

//Redux
import { useSelector, useDispatch } from 'react-redux';

// Util
import { mail, tlfn } from '../../util/ValidarCampos';

import { solicitudUsuario } from '../../actions/authActions';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 275,
        marginBottom: "1.5em"
    },
}));

const Ayuda = () => {

    const usuario = useSelector((state) => state.auth.usuario);

    const classes = useStyles();
    const [camposVacios, handleCamposVacios] = useState(false)
    const [stateTelefono, handleTelefono] = useState(false)
    const [stateEmail, handleEmail] = useState(false)

    const dispatch = useDispatch();

    const [state, setState] = useState(false);
    const [field, setField] = useState({
        nombre: usuario[0].nombre,
        apellido: usuario[0].apellido,
        telefono: '',
        correo: usuario[0].email,
        mensaje: ''
    });

    const { nombre, apellido, telefono, correo, mensaje } = field;

    const handleField = (e) => {
        setField({
            ...field,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (telefono.length < 1 ||
            correo.length < 1 ||
            mensaje.length < 1) {
            handleCamposVacios(true);
            return null;
        }

        if (telefono.length > 1 ||
            correo.length > 1 ||
            mensaje.length > 1) {
            handleCamposVacios(false);
        }

        if (!tlfn.test(telefono)) {
            handleTelefono(true);
            return null;
        }

        if (!mail.test(correo)) {
            handleEmail(true);
            return null;
        }

        dispatch(solicitudUsuario(field))
        setState(true);
        setTimeout(() => {
            setState(false);
        }, 6000)
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item sm={12}>
                {state ? <Alert severity="info">Pronto nos comunicaremos contigo, gracias por contactarnos</Alert> : null}
                <Card className={classes.root}>
                    <CardContent>
                        <Typography
                            variant="h6"
                            align="center">
                            Necesitas ayuda, Jalliday siempre a tu lado
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            align="center"
                            className="mt-3">
                            Si necesitas ayuda o tíenes problema con tus pedidos, te invitamos a que completes
                            el formulario que se encuentra en la parte inferior, completando cada uno de los
                            campos con tu solicitud, al enviar el formulario nos comunicaremos contigo
                            brevemente.
                        </Typography>
                        <form
                            onSubmit={onSubmit}
                            className="my-5"
                            noValidate
                            autoComplete="off">
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                className="mb-5">
                                <Grid item sm={12}>
                                    {camposVacios ?
                                        (<Alert severity="warning">Por favor, complete todos los campos</Alert>)
                                        : null}
                                </Grid>
                                <Grid item md={6} sm={10}>
                                    <FormControl fullWidth className={classes.margin}>
                                        <InputLabel htmlFor="nombre">Nombre(s)</InputLabel>
                                        <Input
                                            type="text"
                                            id="nombre"
                                            name="nombre"
                                            placeholder="Ingresa tu(s) nombre(s)*"
                                            required
                                            value={nombre} />
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} sm={10}>
                                    <FormControl fullWidth className={classes.margin}>
                                        <InputLabel htmlFor="apellido">Apellido(s)</InputLabel>
                                        <Input
                                            type="text"
                                            id="apellido"
                                            name="apellido"
                                            placeholder="Ingresa tu(s) apellido(s)*"
                                            required
                                            value={apellido} />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                className="mb-5">
                                <Grid item md={6} sm={10}>
                                    {stateTelefono ?
                                        (<Alert severity="error">Tu télefono no cumple con los requisitos</Alert>)
                                        :
                                        null}
                                    <FormControl fullWidth className={classes.margin}>
                                        <InputLabel htmlFor="telefono">Télefono</InputLabel>
                                        <Input
                                            type="text"
                                            id="telefono"
                                            name="telefono"
                                            placeholder="Ingresa tu télefono*"
                                            required
                                            onChange={handleField}
                                            value={telefono} />
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} sm={10}>
                                    {stateEmail ?
                                        (<Alert severity="error">Tu E-Mail no cumple con los requisitos</Alert>)
                                        :
                                        null}
                                    <FormControl fullWidth className={classes.margin}>
                                        <InputLabel htmlFor="correo">Correo Electrónico</InputLabel>
                                        <Input
                                            type="text"
                                            id="correo"
                                            name="correo"
                                            placeholder="Ingresa tu correo electrónico*"
                                            required
                                            onChange={handleField}
                                            value={correo} />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                className="my-3" >
                                <Grid item sm={12} className="mb-3">
                                    <FormControl fullWidth className={classes.margin}>
                                        <TextField
                                            id="mensaje"
                                            label="Mensaje"
                                            name="mensaje"
                                            rows={4}
                                            multiline
                                            variant="outlined"
                                            onChange={handleField}
                                            value={mensaje}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center" >
                                <Grid item sm={12} className="mb-3">
                                    <FormControl fullWidth className={classes.margin}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                        >
                                            Enviar Solicitud
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Ayuda;
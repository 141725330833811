import { imagen } from '../util/rutas';
const initialState = {
    cosmeticos: [{
        img: `${imagen}basar/abiales.jpg`,
        link: "Labial",
        id: 1,
    },
    {
        img: `${imagen}basar/Lápices-delineadores.jpg`,
        link: "delineador",
        id: 2,
    },
    {
        img: `${imagen}basar/polvos-compactos-1024x682.jpg`,
        link: "Sombra",
        id: 3,
    },
    {
        img:  `${imagen}basar/resenas-infallible-loreal-01.jpg`,
        link: "Base",
        id: 4,
    },
    {
        img: `${imagen}basar/52983-brocha-para-rubor-y-polvo.jpg`,
        link: "Rubor",
        id: 5,
    },
    {
        img:  `${imagen}basar/varios.jpg`,
        link: "Mascara",
        id: 6,
    }],

    cafeteria: [{
        img: `${imagen}basar/caferouster.jpg`,
        link: "Sweet",
        id: 1,
    },
    {
        img: `${imagen}basar/nescafe-descafeinado-frasco-1-MiMercado.delivery.jpg`,
        link: "Caffe",
        id: 2,
    },
    {
        img: `${imagen}basar/UTB8b4NIfpfJXKJkSamHq6zLyVXaJ.jpg_350x350.jpg`,
        link: "Roasted",
        id: 3,
    },
    {
        img:  `${imagen}basar/descarga.jpg`,
        link: "Sweet",
        id: 4,
    },
    {
        img: `${imagen}basar/900x600.jpg`,
        link: "Caffe",
        id: 5,
    },
    {
        img:  `${imagen}basar/ECW18abXUAAqoQd.jpg`,
        link: "Roasted",
        id: 6,
    }],

    higiene_y_limpieza: [{
        img: `${imagen}basar/0167326.jpeg`,
        link: "Shampoo",
        id: 1,
    },
    {
        img: `${imagen}basar/coc.jpg`,
        link: "Coconut",
        id: 2,
    },
    {
        img: `${imagen}basar/2680461_f.jpg`,
        link: "Toallitas",
        id: 3,
    },
    {
        img:  `${imagen}basar/0213413.jpeg`,
        link: "Shampoo",
        id: 4,
    },
    {
        img: `${imagen}basar/coconut.jpeg`,
        link: "Coconut",
        id: 5,
    },
    {
        img:  `${imagen}basar/5132853_1.jpg`,
        link: "Shampoo",
        id: 6,
    }],

    Farmacos_y_medicamentos: [{
        img: `${imagen}basar/7502223708150_1.jpg`,
        link: "complejo",
        id: 1,
    },
    {
        img: `${imagen}basar/but-cep-462-super-tip-mediano.jpg`,
        link: "cepillo",
        id: 2,
    },
    {
        img: `${imagen}basar/descarga1.jpg`,
        link: "acetaminofen",
        id: 3,
    },
    {
        img:  `${imagen}basar/garden_house_magnesio_400.jpg`,
        link: "magnecio",
        id: 4,
    },
    {
        img: `${imagen}basar/ibuprofeno.jpg`,
        link: "ibuprofeno",
        id: 5,
    },
    {
        img:  `${imagen}basar/lora.jpg`,
        link: "loratadina",
        id: 6,
    }],
}

export default (state = initialState, action) => {
    switch (action.type) {

        default:
            return state;
    }
}
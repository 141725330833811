export const porcentaje = 0.08;
export const transporte = 5.0;
export const transporte_arg = 200.00;
export const transporte_us = 5.0;
// Contectar
export const phone = "(+58) 0424 714 7712";
export const correo_pedido = "jallidayca@gmail.com";
export const correo_soporte = "jallidaysoporte@gmail.com";
//Frase
export const frase = "Jalliday, siempre a tu lado";

import React, { Suspense } from 'react';

// MAterial
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';

// Modal
const ModalCenterSegurity = React.lazy(() => import('../modals/ModalCenterSegurity'));
const ModalUpdateAccount = React.lazy(() => import('../modals/ModalUpdateAccount'));

// Router
import { useHistory } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
    showModalAccount,
    showModalUpdateCuenta
} from '../../actions/modalActions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const CenterSegurity = () => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const authentification = useSelector((state) => state.auth.authentification);

    const handleUpdateData = (estado) => {
        if (authentification) {
            history.push({
                pathname: '/perfil',
                state: 1
            });
        }
        dispatch(showModalUpdateCuenta(estado))
    }

    return (
        <Grid item sm={4} xs={12}>
            <List component="nav" className={classes.root} aria-label="mailbox folders">
                <ListItem>
                    <ListItemText primary="Centro de seguridad" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleUpdateData(true)}
                    divider>
                    <ListItemText
                        className="text-primary decoration"
                        primary="¿Cómo actualizar los datos de mi cuenta?" />
                </ListItem>
                <Divider />
                <ListItem
                    button
                    onClick={() => dispatch(showModalAccount(true))}>
                    <ListItemText
                        className="text-primary decoration"
                        primary="¿Cómo me registro?" />
                </ListItem>
                <Divider />
            </List>

            {/**************************  Modal *****************************/}
            <Suspense fallback={null}>
                <ModalCenterSegurity />
                <ModalUpdateAccount />
            </Suspense>

        </Grid>
    );
}

export default CenterSegurity;
import {
    SET_COMUNIDAD,
    ERROR_SET_COMUNIDAD
    } from '../types';

    const stateInitial = {
        message: null,
    }

export default (state = stateInitial, actions) => {
    switch (actions.type) {

        case SET_COMUNIDAD:
        case ERROR_SET_COMUNIDAD: 
        return {
            ...state,
            message: actions.payload
        }
    
        default:
            return state
    }
}
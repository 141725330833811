import React, { useEffect } from 'react';

// Components
import LoadingPage from './LoadingPage';
import Account from '../auth/Account';
import NewAccount from '../auth/NewAccount';
import ResetPassword from '../auth/ResetPassword';
import Help from '../components/Help/Help';
import WhatOasis from '../components/Oasis/WhatOasis';
import List from '../components/List-Items/List';
import Item from '../components/List-Items/Item';
import Offer from '../components/offer/Offer';
import Perfil from '../components/perfil/Perfil';
import RestaurarPassword from '../auth/RestaurarPassword';
import Pay from '../components/Pay/Pay';
import PaySuccess from '../components/Pay/PaySuccess';
import PayError from '../components/Pay/PayError';
import ServicioCliente from '../components/Service/ServicioCliente';
import Messages from './message/Messages';
import NotFound from '../components/404/NotFound';

// Route Privada
import RoutePrivate from '../route/routePrivate';
import RestaurarPrivate from '../route/RestaurarPrivate';
import PayPrivate from '../route/PayPrivate';
import MessageRoute from '../route/MessageRoute';
import SuccessRoute from '../route/Success';
import ErrorRoute from '../route/ErrorRoute';

// Reducer
import { Provider, useDispatch, useSelector } from 'react-redux';

// React Router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Store
import store from '../store';

//token
import tokenAuth from '../config/token';

// Actions
import { autentificacion, infoUser } from '../actions/authActions';
import { get_categoria } from '../actions/categoriaActions';

// Bootstrap
import 'bootswatch/dist/cosmo/bootstrap.min.css';

const token = localStorage.getItem("token");
if (token) tokenAuth(token);

const AppWrapper = () => {

  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

const App = () => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.auth.allUser);

  useEffect(() => {
    dispatch(autentificacion());
    dispatch(infoUser());
    dispatch(get_categoria());
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path={"/"} component={LoadingPage} />
        <Route exact path={"/account"} component={Account} />
        <Route exact path={"/newAccount"} component={NewAccount} />
        <Route exact path={"/resetPassword"} component={ResetPassword} />
        <Route exact path={"/help"} component={Help} />
        <Route exact path={"/what-is-oasis"} component={WhatOasis} />
        <Route exact path={`/list-items/:url`} component={List} />
        <Route exact path={"/offert"} component={Offer} />
        <Route path={`/item/:url/:id`} render={() => <Item states={state} />} />
        <Route exact path={"/servicio-cliente"} component={ServicioCliente} />
        <PayPrivate exact path={"/pay"} component={Pay} />
        <RestaurarPrivate exact path={"/rest/:id"} component={RestaurarPassword} />
        <RoutePrivate exact path={"/perfil"} component={Perfil} />
        <MessageRoute exact path={"/messages"} component={Messages} />
        <SuccessRoute exact path={"/pay-success"} component={PaySuccess} />
        <ErrorRoute exact path={"/pay-error"} component={PayError} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default AppWrapper;

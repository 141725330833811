import React, { useState } from 'react';

// Material
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BuildIcon from '@material-ui/icons/Build';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// Redux
import { useSelector } from 'react-redux';

// Components
import MisDatos from '../components/perfil/MisDatos';
import Configuracion from '../components/perfil/Configuracion';
import Ayuda from '../components/perfil/Ayuda';
import Pedidos from '../components/perfil/Pedidos';
import Pagos from '../components/perfil/Pagos';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const SideBarAuth = ({ estado }) => {
  const classes = useStyles();
  const [value, setValue] = useState(estado ? estado : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Redux
  const usuario = useSelector((state) => state.auth.usuario);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example">
            <Tab label="Mís datos" icon={<PersonPinIcon />} {...a11yProps(0)} />
            <Tab label="Configuración" className="hide" icon={<BuildIcon />} {...a11yProps(1)} />
            <Tab label="Pagos" icon={<ShoppingCartIcon />} {...a11yProps(2)} />
            <Tab label="Pedidos" icon={<ListAltIcon />} {...a11yProps(3)} />
            <Tab label="Ayuda" className="hide" icon={<HelpIcon />} {...a11yProps(4)} />
          </Tabs>
        </Grid>
      </AppBar>
      <TabPanel value={value} index={0}>
        {usuario ?
          usuario.map(users => (
            <MisDatos
              key={users._id}
              data={users}
            />
          )) : null}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Configuracion />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Pagos />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Pedidos />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Ayuda />
      </TabPanel>
    </div>
  );
}

SideBarAuth.propTypes = {
  estado: PropTypes.bool
}

export default SideBarAuth;
import React, { Fragment } from "react";

// Redux 
import { useSelector } from 'react-redux';

import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";


const Slide = () => {

  const slide = useSelector((state) => state.charcuteria.charcuteria);

  return (
    <Fragment>
      <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} >
        {slide.map(resp => (
          <img src={resp.images} style={{ height: "400px", color: "#fff" }} key={resp.id} alt={resp.description} />
        ))}
      </Carousel>
    </Fragment>
  );
}

export default Slide;
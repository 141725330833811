// MODALS
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL_AUTH = 'SHOW_MODAL_AUTH';
export const SHOW_MODAL_TERMS = 'SHOW_MODAL_TERMS';
export const SHOW_MODAL_PRIVACIDAD = 'SHOW_MODAL_PRIVACIDAD';
export const SHOW_MODAL_ACCOUNT = 'SHOW_MODAL_ACCOUNT';
export const SHOW_UPDATE_ACCOUNT = 'SHOW_UPDATE_ACCOUNT';
export const SHOW_LIST_ITEM = 'SHOW_LIST_ITEM';
export const SHOW_MODAL_CARRITO = 'SHOW_MODAL_CARRITO';
export const SHOW_FINALIZAR_PAGO = 'SHOW_FINALIZAR_PAGO';
export const SHOW_CANCELAR_PEDIDO = 'SHOW_CANCELAR_PEDIDO';
export const SHOW_PAGO_EXTERIOR = 'SHOW_PAGO_EXTERIOR';
export const SHOW_PROBLEMAS_PEDIDOS = 'SHOW_PROBLEMAS_PEDIDOS';
export const SHOW_RECHAZO_PEDIDOS = 'SHOW_RECHAZO_PEDIDOS';
export const SHOW_PRODUCTOS = 'SHOW_PRODUCTOS';

// Auth
export const LOGIN = 'LOGIN';
export const DATA_AUTH = 'DATA_AUTH';
export const DATA_AUTH_ERROR = 'DATA_AUTH_ERROR';
export const CREAR_CUENTA = 'CREAR_CUENTA';
export const ERROR_CUENTA = 'ERROR_CUENTA';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const CLOSE_SESSION = 'CLOSE_SESSION';
export const MODIFICAR_USUARIO = 'MODIFICAR_USUARIO';
export const ERROR_MODIFICAR_USUARIO = 'ERROR_MODIFICAR_USUARIO';
export const RECUPERAR_EMAIL = 'RECUPERAR_EMAIL';
export const ERROR_RECUPERAR_EMAIL = 'ERROR_RECUPERAR_EMAIL';
export const EXITO_PASSWORD = 'ERROR_PASSWORD';
export const ERROR_RECUPERAR_PASSWORD = 'ERROR_RECUPERAR_PASSWORD';
export const RECUPERAR_PASSWORD = 'RECUPERAR_PASSWORD';
export const CHANGE_RECUPERAR_PASSWORD = 'CHANGE_RECUPERAR_PASSWORD';

// Input_Search_Producto
export const INPUT_SEARCH = 'INPUT_SEARCH';
export const GET_PRODUCTO_ID = 'GET_PRODUCTO_ID';
export const ERROR_INPUT_SEARCH = 'ERROR_INPUT_SEARCH';
export const ERROR_PRODUCTO_ID = 'ERROR_PRODUCTO_ID';
export const LIST_ITEM = 'LIST_ITEM';

// Carrito
export const AGREGAR_CARRITO = 'AGREGAR_CARRITO';
export const AGREGAR_TOTAL = 'AGREGAR_TOTAL';
export const AGREGAR_SUMA = 'AGREGAR_SUMA';
export const UPDATE_ESTADO = 'UPDATE_ESTADO';
export const RESTAR_PRODUCTO = 'RESTAR_PRODUCTO';
export const SUMAR_ITEM = 'SUMAR_ITEM';
export const EMPTY = 'EMPTY';
export const EMPTY_CARRITO = 'EMPTY_CARRITO';
export const EMPTY_ITEM = 'EMPTY_ITEM';
export const GET_COUNT_CAR = 'GET_COUNT_CAR';

// PEDIDO
export const INSERTAR_CODIGO = 'INSERTAR_CODIGO';
export const ERROR_INSERTAR_CODIGO = 'ERROR_INSERTAR_CODIGO';
export const OBTENER_PEDIDO = 'OBTENER_PEDIDO';
export const ERROR_OBTENER_PEDIDO = 'ERROR_OBTENER_PEDIDO';
export const MOTIVO_EXITO = 'MOTIVO_EXITO';
export const ERROR_MOTIVO = 'ERROR_MOTIVO';

// Message
export const OBTENER_MESSAGE = 'OBTENER_MESSAGE';
export const ERROR_OBTENER_MESSAGE = 'ERROR_OBTENER_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const ERROR_DELETE_MESSAGE = 'ERROR_DELETE_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const ERROR_UPDATE_MESSAGE = 'ERROR_UPDATE_MESSAGE';

// Pagos
export const OBTENER_PAGOS = 'OBTENER_PAGOS'
export const ERROR_OBTENER_PAGOS = 'ERROR_OBTENER_PAGOS'
export const OBTENER_PAGO = 'OBTENER_PAGO'
export const ERROR_OBTENER_PAGO = 'ERROR_OBTENER_PAGO'
export const OBTENER_PAGO_CHILE = 'OBTENER_PAGO_CHILE'
export const ERROR_OBTENER_PAGO_CHILE = 'ERROR_OBTENER_PAGO_CHILE'

// Comunidad
export const SET_COMUNIDAD = 'SET_COMUNIDAD';
export const ERROR_SET_COMUNIDAD = 'ERROR_SET_COMUNIDAD';

// Categoria
export const GET_CATEGORY = 'GET_CATEGORY';
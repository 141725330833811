import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// Material
import {
    Grid,
    FormControl,
    InputLabel,
    Input,
    InputAdornment
} from '@material-ui/core';

// components
import BtnAuth from './BtnAuth';
import Logo from './Logo';

// Actions
import { inputSeach } from '../../actions/Items';

import SearchIcon from '@material-ui/icons/Search';

// Redux
import { useDispatch } from 'react-redux';

// Cookie
import { setCookie, getCookie } from '../../util/cookie';

const Search = () => {

    const [search, handleSearch] = useState('');

    // Dispatch redux
    const dispatch = useDispatch();

    // History
    const history = useHistory();

    const onSearch = (e) => {
        if (search.length < 1) return null;
        const busqueda = getCookie('LAST_SEARCH');
        const variable = `s=${busqueda}`
        if (e.key === "Enter") {
            dispatch(inputSeach());
            history.push(`/list-items/${variable}`)
        }
    }

    const handleCookie = (e) => {
        setCookie('LAST_SEARCH', e.target.value);
        handleSearch(e.target.value)
    }

    useEffect(() => {
        dispatch(inputSeach());
    }, [])

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >

            <Logo />

            {/**
             * Esta parte de aca es el Input
             */}
            <Grid item md={5} sm={8}>
                <FormControl fullWidth >
                    <InputLabel htmlFor="standard-adornment-amount">Buscar...</InputLabel>
                    <Input
                        id="search"
                        name="search"
                        value={search}
                        onChange={(e) => handleCookie(e)}
                        onKeyDown={(e) => onSearch(e)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
            </Grid>

            <BtnAuth />

        </Grid>
    );
}

export default Search;
import React, { useState, useEffect } from 'react';

// Components
import NavbarOpcitional from '../layout/NavbarOpcitional';

// Util
import { getParameterByName } from '../util/rescuperarPassword';
import { pass } from '../util/ValidarCampos';

// Alert
import Alert from '@material-ui/lab/Alert';

// actions
import { recuperarPassword, changeRecuperar, ChangeRecuperarNot } from '../actions/authActions';

// Router
import { useHistory } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Material
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Input,
    InputLabel,
    FormControl,
    Button,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const RestaurarPassword = () => {

    const classes = useStyles();
    const auth = useSelector((state) => state.auth);
    const { confirm, restaurar, mensaje } = auth;
    const dispatch = useDispatch();

    const history = useHistory();

    const _id = getParameterByName('rest');

    const [estados, setestados] = useState({
        config: '',
        message: '',
    });

    const [passw, setpassword] = useState({
        password: '',
        passwordCopia: '',
    });

    const { config, message } = estados;
    const { password, passwordCopia } = passw;

    const handleField = (e) => {
        setpassword({
            ...passw,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (!pass.test(password) && !pass.test(passwordCopia)) {
            setestados({
                config: 'error',
                message: 'La contraseña no cumple con los requisitos'
            });
            return null;
        } else {
            setestados({
                config: '',
                message: ''
            });
        }

        if (password !== passwordCopia) {
            setestados({
                config: 'error',
                message: 'Las contraseñas ingresadas no coinciden',
            });
            return null;
        }

        dispatch(recuperarPassword(_id, password));

        if (confirm) {
            dispatch(ChangeRecuperarNot(true));
            history.push('/');
        }

    }

    useEffect(() => {
        const _id = getParameterByName('rest');
        if (_id !== '' || _id.length > 15) {
            dispatch(changeRecuperar(false));
        } else {
            history.push('/');
        }
    }, [_id, restaurar])

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item sm={12}>
                <NavbarOpcitional />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item sm={5}>
                    <Card className={classes.root + " border border-warning my-5"}>
                        <CardContent>
                            <Typography
                                align="center"
                                variant="h4"
                                color="textSecondary">
                                Restaurar Contraseña
                            </Typography>
                            <form onSubmit={onSubmit}
                                className="mt-3">
                                {message !== '' ?
                                    (<Alert severity={config}>{message}</Alert>)
                                    :
                                    null}
                                {mensaje ?
                                    (<Alert severity={mensaje.style}>{mensaje.msg}</Alert>)
                                    :
                                    null}
                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel
                                        htmlFor="password"
                                        className="lead">
                                        Password:
                                    </InputLabel>
                                    <Input
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="(Mínimo 8 caracteres, letras (mayúsculas y minúsculas), numeros y caracteres espaciales)*"
                                        onChange={handleField}
                                        value={password}
                                        required />
                                </FormControl>

                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel
                                        htmlFor="passwordCopia"
                                        className="lead">
                                        Repita Password:
                                    </InputLabel>
                                    <Input
                                        type="password"
                                        id="passwordCopia"
                                        name="passwordCopia"
                                        placeholder="Repita su contraseña"
                                        onChange={handleField}
                                        value={passwordCopia}
                                        required />
                                </FormControl>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="mb-2 w-100">
                                    Restablecer
                                </Button>

                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RestaurarPassword;
import {
    OBTENER_PAGOS,
    ERROR_OBTENER_PAGOS,
    OBTENER_PAGO,
    ERROR_OBTENER_PAGO,
    OBTENER_PAGO_CHILE,
    ERROR_OBTENER_PAGO_CHILE,
} from '../types';

const initialState = {
    pago: [],
    pagos: [],
    message: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_PAGOS:
            return {
                ...state,
                pago: action.payload
            }
        case OBTENER_PAGO:
        case OBTENER_PAGO_CHILE:
            return {
                ...state,
                pagos: action.payload,
            }
        case ERROR_OBTENER_PAGOS:
        case ERROR_OBTENER_PAGO:
        case ERROR_OBTENER_PAGO_CHILE:
            return {
                ...state,
                message: action.payload,
            }
        default:
            return state
    }
}
import {
    CREAR_CUENTA,
    ERROR_CUENTA,
    DATA_AUTH,
    DATA_AUTH_ERROR,
    LOGIN,
    LOGIN_ERROR,
    OBTENER_USUARIO,
    CLOSE_SESSION,
    MODIFICAR_USUARIO,
    ERROR_MODIFICAR_USUARIO,
    RECUPERAR_EMAIL,
    ERROR_RECUPERAR_EMAIL,
    EXITO_PASSWORD,
    ERROR_RECUPERAR_PASSWORD,
    RECUPERAR_PASSWORD,
    CHANGE_RECUPERAR_PASSWORD,
} from '../types';

// Axios
import axios from '../config/axios';

// token
import autentificarToken from '../config/token';

export const crear_cuenta = (data) => {
    return async (dispatch) => {
        try {
            const new_account = await axios.post("users", data);
            dispatch({
                type: CREAR_CUENTA,
                payload: new_account.data
            });
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_CUENTA,
                payload: alert,
            });
        }
    }
}

export const modificarUsuario = (id, data) => {
    return async (dispatch) => {
        try {
            const updateUsers = await axios.put(`users/${id}`, data);
            dispatch({
                type: MODIFICAR_USUARIO,
                payload: updateUsers
            });
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_MODIFICAR_USUARIO,
                payload: alert
            });
        }
    }
}

export const autentificacion = () => {
    return async (dispatch) => {
        const token = localStorage.getItem('token');
        if (token) {
            autentificarToken(token)
        }
        try {
            const usuario = await axios.get("auth");
            // the information is obtained because we pass the token through the header
            dispatch({
                type: OBTENER_USUARIO,
                payload: usuario.data.usuario
            })
        } catch (error) {
            dispatch({
                type: ERROR_CUENTA
            })
        }
    }
}

export const infoUser = () => {
    return async (dispatch) => {
        try {
            const api = await fetch("https://ipapi.co/json/");
            const json = await api.json();
            dispatch({
                type: DATA_AUTH,
                payload: json
            });
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            
            dispatch({
                type: DATA_AUTH_ERROR,
                payload: error.response.data
            });
        }
    }
}

export const login = (data) => {
    return async (dispatch) => {
        try {
            const usuario = await axios.post("auth", data);
            dispatch({
                type: LOGIN,
                payload: usuario.data
            })
            await autentificacion();
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alert,
            })
        }
    }
}

export const solicitudUsuario = (data) => {
    return async () => {
        try {
            await axios.post('email', data);
        } catch (error) {
            console.log(error.response)
        }
    }
}

export const recuperarEmail = (email) => {
    return async (dispatch) => {
        try {
            const recuperar = await axios.post(`email/rest%data%auth/${email}`);
            const alert = {
                msg: recuperar.data.msg,
                style: "success"
            }
            dispatch({
                type: RECUPERAR_EMAIL,
                payload: alert
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                msg: error.response.data.msg,
                style: "error"
            }
            dispatch({
                type: ERROR_RECUPERAR_EMAIL,
                payload: alert
            })
        }
    }
}

export const recuperarPassword = (id, password) => {
    return async (dispatch) => {
        const pass = {
            password
        }
        try {
            await axios.put(`email/${id}`, pass);
            dispatch({
                type: EXITO_PASSWORD,
                payload: true
            })
        } catch (error) {
            if (typeof (error.response) === 'undefined' || typeof (error.response.data) === 'undefined') return null;
            const alert = {
                style: 'error',
                msg: error.response.data.msg,
            }
            dispatch({
                type: ERROR_RECUPERAR_PASSWORD,
                payload: alert
            })
        }
    }
}

export const changeRecuperar = (estado) => {
    return (dispatch) => {
        dispatch({
            type: RECUPERAR_PASSWORD,
            payload: estado
        })
    }
}

export const ChangeRecuperarNot = (estado) => {
    return (dispatch) => {
        dispatch({
            type: CHANGE_RECUPERAR_PASSWORD,
            payload: estado
        })
    }
}

export const closeSession = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_SESSION
        });
    }
}
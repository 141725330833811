import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import Battery90Icon from '@material-ui/icons/Battery90';
import ExtensionIcon from '@material-ui/icons/Extension';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EcoIcon from '@material-ui/icons/Eco';
import BathtubIcon from '@material-ui/icons/Bathtub';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

// Actions
import { inputSeach } from '../actions/Items';

// Cookie
import { setCookie } from '../util/cookie';

// Reux
import { useDispatch } from 'react-redux';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },

    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

const StyledTreeItem = (props) => {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
    },
});

const SideBar = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const handleCategory = (search) => {
        setCookie("LAST_SEARCH", search);
        dispatch(inputSeach());
    }

    const dpto = 'Dpto.';

    return (
        <TreeView
            className={classes.root + ' mb-5'}
            // defaultExpanded={['3', '1']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            style={{ display: 'contents' }}
        >
            <header className="">
                <h2 className="text-center lead">
                    Categorías
                </h2>
            </header>
            <StyledTreeItem nodeId="1"
                labelText={dpto + ' Cafetería'}
                labelIcon={HomeWorkIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="2"
                    labelText="Caffe Roasted"
                    labelIcon={LocalCafeIcon}
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Roasted")}
                />
                <StyledTreeItem
                    nodeId="3"
                    labelText="Caffe Nestle"
                    labelIcon={LocalCafeIcon}
                    color="#a250f5"
                    bgColor="#f3e8fd"
                    onClick={() => handleCategory("Nestle")}
                />
                <StyledTreeItem
                    nodeId="5"
                    labelText="Descafeinado"
                    labelIcon={LocalCafeIcon}
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Descafeinado")}
                />
                <StyledTreeItem
                    nodeId="6"
                    labelText="Sweet Mate"
                    labelIcon={LocalCafeIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Sweet Mate")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="7"
                labelText={dpto + ' Grasas y Aceites'}
                labelIcon={OutdoorGrillIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="8"
                    labelText="Aceite Spray"
                    labelIcon={Battery90Icon}
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Aceite Spray")}
                />
                <StyledTreeItem
                    nodeId="58"
                    labelText="Encendedores"
                    labelIcon={Battery90Icon}
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Fosforo")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="9"
                labelText={dpto + " Cereales y Granos Enteros"}
                labelIcon={ExtensionIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="10"
                    labelText="Garbanzo"
                    labelIcon={ExtensionIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Garbanzo")}
                />
                <StyledTreeItem
                    nodeId="4"
                    labelText="Caraota"
                    labelIcon={ExtensionIcon}
                    color="#3c8039"
                    bgColor="#e6f4ea"
                    onClick={() => handleCategory("Caraota")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="11"
                labelText={dpto + " Salsas y Aderezos"}
                labelIcon={BatteryFullIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="12"
                    labelText="Mostaza"
                    labelIcon={Battery90Icon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Mostaza")}
                />
                <StyledTreeItem
                    nodeId="13"
                    labelText="Salsa de Tomate"
                    labelIcon={Battery90Icon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Salsa de Tomate")}
                />
                <StyledTreeItem
                    nodeId="14"
                    labelText="Mayonesa"
                    labelIcon={Battery90Icon}
                    color="#a250f5"
                    bgColor="#f3e8fd"
                    onClick={() => handleCategory("Mayonesa")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="15"
                labelText={dpto + " Pescado y Enlatados"}
                labelIcon={ShoppingCartIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="16"
                    labelText="Atún Bumble Bee"
                    labelIcon={ShoppingCartIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Bumble Bee")}
                />
                <StyledTreeItem
                    nodeId="17"
                    labelText="Atún CRIDER"
                    labelIcon={ShoppingCartIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Crider")}
                />
                <StyledTreeItem
                    nodeId="18"
                    labelText="Vegetales Enlatados"
                    labelIcon={EcoIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Arvejas")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="19"
                labelText={dpto + " Pastas y Fideos"}
                labelIcon={ShoppingCartIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="20"
                    labelText="Pasta Egg Noodles"
                    labelIcon={SportsCricketIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Egg Noodles")}
                />
                <StyledTreeItem
                    nodeId="21"
                    labelText="Pasta Sald & Ranch Suddenly"
                    labelIcon={SportsCricketIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Suddenly")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="22"
                labelText={dpto + " Higiene y Limpieza"}
                labelIcon={BathtubIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="23"
                    labelText="Power Stick"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Power Stick")}
                />
                <StyledTreeItem
                    nodeId="24"
                    labelText="Speed Stick"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Speed Stick")}
                />
                <StyledTreeItem
                    nodeId="25"
                    labelText="Lady Speed"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Lady Speed")}
                />
                <StyledTreeItem
                    nodeId="26"
                    labelText="Desodorantes Suave"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Suave")}
                />
                <StyledTreeItem
                    nodeId="27"
                    labelText="Jabones"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Jabon")}
                />
                <StyledTreeItem
                    nodeId="28"
                    labelText="Toallitas"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Toallitas")}
                />
                <StyledTreeItem
                    nodeId="29"
                    labelText="Detergente"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Detergente")}
                />
                <StyledTreeItem
                    nodeId="30"
                    labelText="Hísopos Cotton"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Cotton")}
                />
                <StyledTreeItem
                    nodeId="31"
                    labelText="Crema Coconut"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Coconut")}
                />
                <StyledTreeItem
                    nodeId="32"
                    labelText="Crema Dermasil"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Crema Dermasil")}
                />
                <StyledTreeItem
                    nodeId="33"
                    labelText="Shampoos"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Shampoo")}
                />
                <StyledTreeItem
                    nodeId="34"
                    labelText="Acondicionador"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory("Acondicionador")}
                />
                <StyledTreeItem
                    nodeId="35"
                    labelText="Limpiador Ajax"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Ajax")}
                />
                <StyledTreeItem
                    nodeId="36"
                    labelText="Enjuague Bucal"
                    labelIcon={BathtubIcon}
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={() => handleCategory(" Bucal")}
                />
                <StyledTreeItem
                    nodeId="37"
                    labelText="Cremas Dentales"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Crema Dental")}
                />
                <StyledTreeItem
                    nodeId="47"
                    labelText="Cepillo Dental"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Cepillo Dental ")}
                />
                <StyledTreeItem
                    nodeId="57"
                    labelText="Servilleta Napkins"
                    labelIcon={BathtubIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Servilleta")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="38"
                labelText={dpto + " Galletas y Golosinas"}
                labelIcon={SettingsInputSvideoIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="39"
                    labelText="Galletas Saltin"
                    labelIcon={SettingsInputSvideoIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Saltin")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="40"
                labelText={dpto + " Fármacos y Medicamentos"}
                labelIcon={LocalHospitalIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="41"
                    labelText="Linea Nature Measure"
                    labelIcon={FavoriteIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Nature Measure")}
                />
                <StyledTreeItem
                    nodeId="42"
                    labelText="Ibuprofeno"
                    labelIcon={FavoriteIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Ibuprofeno")}
                />
                <StyledTreeItem
                    nodeId="43"
                    labelText="Aspirina"
                    labelIcon={FavoriteIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Aspirina")}
                />
                <StyledTreeItem
                    nodeId="44"
                    labelText="Magnecio"
                    labelIcon={FavoriteIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Magnecio")}
                />
                <StyledTreeItem
                    nodeId="45"
                    labelText="Loratadina"
                    labelIcon={FavoriteIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Loratadina")}
                />
                <StyledTreeItem
                    nodeId="46"
                    labelText="Acetaminofén"
                    labelIcon={FavoriteIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Acetaminofén")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="48"
                labelText={dpto + " Cosmético"}
                labelIcon={LoyaltyIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="49"
                    labelText="Sombra Revol"
                    labelIcon={LoyaltyIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Sombra Revlon")}
                />
                <StyledTreeItem
                    nodeId="50"
                    labelText="Rubor Revlon "
                    labelIcon={LoyaltyIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Rubor Revlon ")}
                />
                <StyledTreeItem
                    nodeId="51"
                    labelText="Megawear Mascara"
                    labelIcon={LoyaltyIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Megawear Mascara")}
                />
                <StyledTreeItem
                    nodeId="52"
                    labelText="Lápices Labiales"
                    labelIcon={LoyaltyIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Lapiz Labial")}
                />
                <StyledTreeItem
                    nodeId="53"
                    labelText="Delineadores"
                    labelIcon={LoyaltyIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Delineador")}
                />
                <StyledTreeItem
                    nodeId="54"
                    labelText="Bases de Maquillaje"
                    labelIcon={LoyaltyIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Base")}
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="55"
                labelText={dpto + " Bebidas y Lácteos"}
                labelIcon={FastfoodIcon}
                className="text-primary">
                <StyledTreeItem
                    nodeId="56"
                    labelText="Linea Nestlé"
                    labelIcon={FastfoodIcon}
                    textColor="primary"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={() => handleCategory("Nestlé")}
                />
            </StyledTreeItem>
        </TreeView>
    );
}

export default SideBar;
import React, { Fragment } from 'react';

// Components
import NavbarOpcitional from '../layout/NavbarOpcitional';
import FormAccount from './FormAccount';

const Account = () => {

    return (
        <Fragment>
            <NavbarOpcitional />
            <FormAccount
                perfils={false} />
        </Fragment>
    );
}

export default Account;
import React, { useState } from 'react';

// Material
import {
    Grid,
    Card,
    CardContent,
    Checkbox,
    Typography,
    makeStyles,
    FormControl,
    Input,
    InputLabel
} from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

// Util
import { tlfn, pass } from '../../util/ValidarCampos';

import Alert from '@material-ui/lab/Alert';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Actions 
import { modificarUsuario, autentificacion } from '../../actions/authActions';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginBottom: "1.5em"
    },
    margin: {
        margin: theme.spacing(1),
        width: "90%",
        paddingBottom: "1em",
        position: 'relative',
        float: 'right',
        fontWeight: 300,
    },
    checkRight: {
        position: 'relative',
        float: 'right',
        marginTop: "-1.3em",
    },
}));

const Configuracion = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const id = useSelector((state) => state.auth.usuario);

    const [field, fieldSpace] = useState(false);
    const [alert, handleAlert] = useState(false);
    const [passwordV, validPassword] = useState(false);
    const [telefonoV, validTelefono] = useState(false);
    const [password, handlePassword] = useState('');
    const [direccion, handleDireccion] = useState('');
    const [telefono, handleTelefono] = useState('');
    const [checkedPass, setCheckedPassword] = useState(false);
    const [checkedDir, setCheckedDireccion] = useState(false);
    const [checkedTel, setCheckedTelefono] = useState(false);

    const handleSendPassword = (e) => {
        if (e.key === 'Enter') {
            if (password.length < 1) {
                fieldSpace(true);
                return null;
            }

            if (password.length > 0) fieldSpace(false);

            if (!pass.test(password)) {
                validPassword(true);
                return null;
            }

            else validPassword(false);

            let data = {
                "password": password
            }

            if (dispatch(modificarUsuario(id[0]._id, data))) {
                handleAlert(true);
            }

            setTimeout(() => {
                handleAlert(false);
                dispatch(autentificacion());
            }, 1500)
        }
    }

    const handleSendDireccion = (e) => {
        if (e.key === 'Enter') {
            if (direccion.length < 1) {
                fieldSpace(true);
                return null;
            }

            if (direccion.length > 0) fieldSpace(false)

            let data = {
                "direccion": direccion
            }

            if (dispatch(modificarUsuario(id[0]._id, data))) {
                handleAlert(true)
            }

            setTimeout(() => {
                handleAlert(false);
                dispatch(autentificacion());
            }, 1500)
        }
    }

    const handleSendTelefono = (e) => {
        if (e.key === 'Enter') {
            if (telefono.length < 1) {
                fieldSpace(true);
                return null;
            }

            if (telefono.length > 0) fieldSpace(false);

            if (!tlfn.test(telefono)) {
                validTelefono(true);
                return null;
            }
            else validTelefono(false);

            let data = {
                "telefono": telefono
            }

            if (dispatch(modificarUsuario(id[0]._id, data)))
                handleAlert(true);

            setTimeout(() => {
                handleAlert(false);
                dispatch(autentificacion());
            }, 1000)

        }
    }

    const handleChangePass = (event) => {
        setCheckedPassword(event.target.checked);
    };

    const handleChangeDir = (event) => {
        setCheckedDireccion(event.target.checked);
    };

    const handleChangeTel = (event) => {
        setCheckedTelefono(event.target.checked);
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item sm={10} className={classes.root} >
                <Card className={classes.root}>
                    <CardContent>
                        <Typography
                            variant="subtitle2"
                            className="mt-3"
                        >
                            Actualizar Contraseña
                        </Typography>
                        <Checkbox
                            className={classes.checkRight}
                            checked={checkedPass}
                            onClick={handleChangePass}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </CardContent>

                    <CardContent>
                        <Typography
                            variant="subtitle2"
                            className="mt-3"
                        >
                            Actualizar Direección
                        </Typography>
                        <Checkbox
                            className={classes.checkRight}
                            checked={checkedDir}
                            onClick={handleChangeDir}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </CardContent>

                    <CardContent>
                        <Typography
                            variant="subtitle2"
                            className="mt-3"
                        >
                            Actualizar Teléfono
                        </Typography>
                        <Checkbox
                            className={classes.checkRight}
                            checked={checkedTel}
                            onClick={handleChangeTel}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </CardContent>
                </Card>
                {alert ?
                    (<Alert severity="success">Tus nuevos registros se actualizado</Alert>)
                    : null
                }
                {checkedPass ?
                    (
                        <Card className={classes.root}>
                            <CardContent>
                                <LockOpenIcon style={{ marginTop: "1em" }} />
                                <FormControl className={classes.margin}>
                                    {field ?
                                        (<Alert severity="error">Por favor, Complete esté campo si desea actualizar la contraseña</Alert>)
                                        : null
                                    }
                                    {passwordV ?
                                        (
                                            <Alert severity="error">La contraseña no cumple con requisitos</Alert>
                                        ) : null
                                    }
                                    <InputLabel
                                        htmlFor="password"
                                        className="lead">
                                        Password:
                            </InputLabel>
                                    <Input
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="(Mínimo 8 caracteres, letras (mayúsculas y minúsculas), numeros y caracteres espaciales)*"
                                        onChange={(e) => handlePassword(e.target.value)}
                                        onKeyDown={handleSendPassword}
                                        value={password} />
                                </FormControl>
                            </CardContent>
                        </Card>
                    )
                    : null
                }

                {checkedDir ?
                    (
                        <Card className={classes.root}>
                            <CardContent>
                                <AddLocationIcon style={{ marginTop: "1em" }} />
                                <FormControl className={classes.margin}>
                                    {field ? (
                                        <Alert severity="error">Por favor, Complete esté campo si desea actualizar la dirección</Alert>
                                    ) : null
                                    }
                                    <InputLabel
                                        htmlFor="direccion"
                                        className="lead">
                                        Dirección de entrega:
                                    </InputLabel>
                                    <Input
                                        type="text"
                                        id="direccion"
                                        name="direccion"
                                        placeholder="Por favor, introduzca la dirección de entrega lo más especifico posible*"
                                        onChange={(e) => handleDireccion(e.target.value)}
                                        onKeyDown={handleSendDireccion}
                                        value={direccion} />
                                </FormControl>
                            </CardContent>
                        </Card>
                    ) : null}

                {checkedTel ?
                    (
                        <Card className={classes.root}>
                            <CardContent>
                                <PhoneAndroidIcon style={{ marginTop: "1em" }} />
                                <FormControl className={classes.margin}>
                                    {field ? (<Alert severity="error">Por favor, Complete esté campo si desea actualizar el télefono</Alert>)
                                        : null
                                    }
                                    {telefonoV ?
                                        (
                                            <Alert severity="error">Él télefono no cumple con requisitos</Alert>
                                        ) : null
                                    }
                                    <InputLabel
                                        htmlFor="telefono"
                                        className="lead">
                                        Teléfono de contacto
                                    </InputLabel>
                                    <Input
                                        type="tel"
                                        id="telefono"
                                        name="telefono"
                                        placeholder="Por favor, introduzca su télefono sin espacios, solo caracteres númericos*"
                                        onChange={(e) => handleTelefono(e.target.value)}
                                        onKeyDown={handleSendTelefono}
                                        value={telefono} />
                                </FormControl>
                            </CardContent>
                        </Card>
                    ) : null}
            </Grid>
        </Grid>
    );
}

export default Configuracion;
import React, { Suspense, useEffect } from 'react';

// Router
import { Link, useHistory } from 'react-router-dom'

// Material
import { Button, MenuItem, Badge, IconButton } from '@material-ui/core'
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

// Actions
import { showModalCarrito } from '../../actions/modalActions';
import { obtenerMessage } from '../../actions/messageActions';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Components
const ModalCarrito = React.lazy(() => import('../modals/ModalCarrito'));

const Sesion = () => {

    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth);
    const items = useSelector((state) => state.items);
    const message = useSelector((state) => state.message.notificacion);
    const { carrito } = items;
    const { authentification, usuario } = auth;
    const history = useHistory();

    const onClick = () => {
        history.push('/messages');
    }

    if (message) {
        var total = message.reduce(function (total, current) {
            if (current.estado) {
                total += 1;
            }
            return total;
        }, 0);
    }

    let count = total
    let contarCarrito = Object.keys(carrito).length;

    useEffect(() => {
        if (authentification && usuario) dispatch(obtenerMessage(usuario[0]._id));
    }, [authentification, usuario, carrito]);

    return (
        <span>
            {!authentification ?
                (<span className="float-right hide">
                    <Link to={"/account"} className="ml-5 text-dark mr-4 text-decoration-none" >
                        <Button aria-controls="simple-menu" aria-haspopup="true" >
                            Inicia Sesión &nbsp;
                                </Button>
                    </Link>

                    <Link to={"/newAccount"} className="text-dark text-decoration-none" >
                        <Button aria-controls="simple-menu" aria-haspopup="true">
                            Crear Cuenta &nbsp;
                        </Button>
                    </Link>
                </span>)
                :

                (
                    <div className="w-20 float-right sticky-top strike">
                        <MenuItem className="float-right px-1 py-1 mb-0"
                            onClick={contarCarrito > 0 ? () => dispatch(showModalCarrito(true)) : null}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                className="btn-car"
                                color="inherit">
                                <Badge badgeContent={contarCarrito} color="secondary">
                                    <AddShoppingCart />
                                </Badge>
                            </IconButton>
                        </MenuItem>
                        <MenuItem onClick={() => onClick()} className="hide">
                            <IconButton aria-label="show 4 new mails" color="inherit">
                                <Badge badgeContent={count} color="secondary">
                                    <NotificationsActiveIcon />
                                </Badge>
                            </IconButton>
                            <p>Notificación</p>
                        </MenuItem>
                    </div>
                )}
            <Suspense fallback={null} >
                <ModalCarrito />
            </Suspense>
        </span>
    );
}

export default Sesion;
import React from 'react';

// Components
import Navbar from '../../layout/Navbar';
import SideBar from '../../layout/SideBar';
import Items from './Items';

// Material
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        marginBottom: "3em",
    }
}));

const Offer = () => {

const classes = useStyles();
    return (
        <div className={classes.root, classes.margin}>
            <Grid container spacing={2}>
                <Grid lg={12} md={12} item xs={12} sm={12}>
                    <Navbar />
                </Grid>
                <Grid item lg={3} md={3} xs={12} sm={12}>
                    <SideBar />
                </Grid>
                <Grid item lg={9} md={9} xs={12} sm={12}>
                    <Items />
                </Grid>
            </Grid>
        </div>
    );
}

export default Offer;
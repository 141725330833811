import React from 'react';

// Material
import {
    Grid,
} from '@material-ui/core';

// Redux
import { useSelector } from 'react-redux';

// Components
import NavbarOpcitional from '../../layout/NavbarOpcitional';
import Message from './Message';

const Messages = () => {

    const notification = useSelector((state) => state.message.notificacion)

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid item
                sm={12}>
                <NavbarOpcitional />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item
                    sm={9}
                    className="my-4">
                    <p className="lead text-center">
                        Notificaciones, Jalliday te informará el estado de tu pedido
                    </p>
                    {notification.length < 1 || notification === 0 ? 
                    <p className="text-center lead">No hay notificaciones disponibes</p> :
                        notification.map(noti =>
                            <Message key={noti._id}
                                noti={noti} />
                        )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Messages;
import React from 'react';

import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

const NavbarOpcitional = () => {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item md={12} sm={12} xs={12} className="icon-left bg-warning-pastel p-3 w-100">
                <Link to={"/"}>
                    <figure>
                        <img src="/imagen/logotipo.png" alt="Jalliday" width="300" className="ml-5" />
                        {/*<figcaption className="text-muted lead h5 font-weight-bold ml-5 hide">Oasis</figcaption>*/}
                    </figure>
                </Link>
            </Grid>
        </Grid>
    );
}

export default NavbarOpcitional;
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// Proptypes
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Spinner = ({ spinner }) => {
    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={spinner}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

Spinner.propTypes = {
    spinner: PropTypes.bool
};

export default Spinner;